/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const _getStoreConfigFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    'blog_posts_per_page',
    'mfblog_index_page_title',
    'mfblog_index_page_meta_title',
    'mfblog_index_page_meta_keywords',
    'mfblog_index_page_meta_description',
    'mfblog_sidebar_search_enabled',
    'mfblog_sidebar_categories_enabled',
    'mfblog_sidebar_recent_posts_enabled',
    'mfblog_sidebar_recent_posts_posts_per_page',
    'mfblog_sidebar_featured_posts_enabled',
    'mfblog_sidebar_popular_posts_enabled',
    'mfblog_sidebar_archive_enabled',
    'mfblog_sidebar_tag_claud_enabled',
    'mfblog_sidebar_custom_enabled',
    'mfblog_sidebar_custom2_enabled',
    'mfblog_sidebar_rss_feed_enabled',
    'mfblog_post_view_comments_number_of_comments',
    'mfblog_product_page_number_of_related_posts',
    'mfblog_post_view_related_products_number_of_products',
    'mfblog_index_page_posts_sort_by'
];

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};

/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireAddToCompareListEvent } from '../../event/compare';

const addProductToCompare = async (args, callback) => {
    const result = await callback(...args);
    fireAddToCompareListEvent(result);

    return result;
};

export default {
    'Store/ProductCompare/Dispatcher': {
        'member-function': {
            addProductToCompare
        }
    }
};

/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-nested-ternary */

import { roundPrice } from 'Util/Price';
import { fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

import PurchaseQuery from '../query/Purchase.query';
import { CURRENCY_CODE, DEALERSHIP_NAMES, DL_VAL_NOT_APPLICABLE } from './common';
import { getCustomerData } from './customer';

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseProductsData */
export const getPurchaseProductsData = async (purchase) => {
    const { products } = purchase;

    return Promise.all(products.map(async (product) => {
        const {
            category,
            id,
            name,
            price,
            quantity,
            full_price,
            make,
            model,
            first_registered,
            mileage,
            body_style,
            displacement,
            fuel,
            power,
            gearbox,
            gearbox_search,
            drivetrain_suspension,
            drivetrain_search,
            car_color,
            technical_inspection,
            equipment_search,
            dealer
        } = product;

        return {
            id,
            name,
            price: +roundPrice(price || 0),
            brand: (make !== 'false') ? make : '',
            variant: (model !== 'false') ? model : '',
            category,
            quantity,
            dimension1: +roundPrice(full_price || 0),
            dimension2: (make !== 'false') ? make : '',
            dimension3: (model !== 'false') ? model : '',
            dimension4: first_registered || '',
            dimension5: mileage || '',
            dimension6: (body_style !== 'false') ? body_style : '',
            dimension7: displacement || '',
            dimension8: (fuel !== 'false') ? fuel : '',
            dimension9: power || '',
            dimension10: (gearbox !== 'false') ? gearbox
                : ((gearbox_search !== 'false') ? gearbox_search : ''),
            dimension11: (drivetrain_suspension !== 'false') ? drivetrain_suspension
                : ((drivetrain_search !== 'false') ? drivetrain_search : ''),
            dimension12: (car_color !== 'false') ? car_color : '',
            dimension13: technical_inspection || '',
            dimension14: (equipment_search !== 'false') ? equipment_search : '',
            dimension15: (dealer !== 'false') ? dealer : ''
        };
    }));
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseShippingData */
export const getPurchaseShippingData = (purchase) => {
    const { shippingAddress, additional_data } = purchase;

    if (!additional_data || !shippingAddress) {
        return {};
    }

    const {
        city,
        postcode,
        region,
        region_id,
        street
    } = shippingAddress;

    return {
        shipping_city: city,
        shipping_region: region,
        shipping_country_id: region_id,
        shipping_street: street.replace(/\r?\n|\r/g, ' '),
        shipping_postcode: postcode
    };
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseCustomerData */
export const getPurchaseCustomerData = async (purchase) => {
    const { additional_data } = purchase;

    if (!additional_data) {
        return {};
    }

    return {
        ...await getCustomerData(),
        shipping_email: getStore().getState().CheckoutReducer?.email || ''
    };
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseEventData */
export const getPurchaseEventData = async (orderId, guestQuoteId, stateData) => {
    const query = PurchaseQuery.getPurchaseField(orderId, guestQuoteId);
    const { purchase } = await fetchQuery(query);
    const {
        orderPaymentMethod,
        customer,
        revenue,
        tax,
        shipping,
        coupon,
        discount_amount
    } = purchase;
    const {
        orderDealership,
        preferred_communication,
        email
    } = stateData;

    return {
        orderPaymentMethod,
        orderDealership: DEALERSHIP_NAMES[orderDealership] || '',
        preferred_communication: preferred_communication || '',
        firstname: customer?.[0]?.firstname || '',
        lastname: customer?.[0]?.lastname || '',
        phone: customer?.[0]?.phone || '',
        customer_email: email || '',
        customer_id: DL_VAL_NOT_APPLICABLE,
        customer_status: DL_VAL_NOT_APPLICABLE,
        ecommerce: {
            currencyCode: CURRENCY_CODE,
            purchase: {
                actionField: {
                    id: orderId,
                    tax: +roundPrice(tax),
                    coupon: coupon === null ? '' : coupon,
                    revenue: +roundPrice(revenue),
                    coupon_discount_amount: discount_amount,
                    shipping: +roundPrice(shipping)
                },
                products: Array.from(await getPurchaseProductsData(purchase))
            }
        }
    };
};

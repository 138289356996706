import {
    FieldContainer as SourceFieldContainer
} from 'SourceComponent/Field/Field.container';
import { validate } from 'Util/Validator';

import { FIELD_TYPE } from './Field.config';

/** @namespace Scandipwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    componentDidMount() {
        const { attr, prefill, handleFieldPrefill } = this.props;

        if (prefill) {
            handleFieldPrefill(attr);
        }
    }

    /**
     * Created to check is CapsLock button is pressed
     */
    handleKeyPressDown(keyEvent) {
        if (keyEvent.getModifierState('CapsLock')) {
            this.setState({ warning: true });
        } else {
            this.setState({ warning: false });
        }
    }

    /**
     * Created to make warning off when focus lost
     */
    handleBlur() {
        this.setState({ warning: false });
    }

    /**
     * Overridden to add handle functions to container functions
     */
    containerFunctions = {
        ...this.containerFunctions,
        handleKeyPressDown: this.handleKeyPressDown.bind(this),
        handleBlur: this.handleBlur.bind(this)
    };

    /**
     * Overridden to pass handle methods to component via props
     */
    containerProps() {
        const { warning } = this.state;
        const { handleKeyPressDown, handleBlur } = this.containerFunctions;

        return {
            ...super.containerProps(),
            handleKeyPressDown,
            handleBlur,
            warning
        };
    }

    // overridden to add terms field type to be validated as checkbox
    validate(data) {
        const { validationRule, type, attr: { name } = {} } = this.props;
        const checkboxTypes = [FIELD_TYPE.checkbox, FIELD_TYPE.radio, FIELD_TYPE.terms];
        const value = checkboxTypes.includes(type)
            ? !!this.fieldRef.checked
            : this.fieldRef.value;
        const response = validate(value, validationRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && response !== true) {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            data.detail.errors.push(output);
        }

        this.setState({ validationResponse: output });

        return output;
    }
}

export default FieldContainer;

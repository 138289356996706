import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import Slider from 'Component/Slider';
import { ChildrenType, RefType } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import { ITEMS_PER_ROW } from './ProductSlider.config';

import './ProductSlider.style';

/** @namespace Scandipwa/Component/ProductSlider/Component */
export class ProductSliderComponent extends PureComponent {
    static propTypes = {
        goLeft: PropTypes.func,
        goRight: PropTypes.func,
        onSlide: PropTypes.func,
        children: ChildrenType.isRequired,
        sliderRef: RefType.isRequired,
        isMobile: PropTypes.bool.isRequired,
        onActiveImageChange: PropTypes.func,
        activeImage: PropTypes.element.isRequired
    };

    static defaultProps = {
        goLeft: noopFn,
        goRight: noopFn,
        onSlide: noopFn,
        onActiveImageChange: noopFn
    };

    renderMobileSlider() {
        const {
            children, activeImage, onActiveImageChange
        } = this.props;

        return (
            <Slider
              mix={ { block: 'ProductSliderWidget' } }
              activeImage={ activeImage }
              onActiveImageChange={ onActiveImageChange }
            >
                { children }
            </Slider>
        );
    }

    renderDesktopSlider() {
        const {
            onSlide, children, sliderRef
        } = this.props;

        return (
            <div block="SliderWrapper">
              <div block="ProductSlider" onScroll={ onSlide } ref={ sliderRef }>
                  { children }
              </div>
              { this.renderArrows() }
            </div>
        );
    }

    renderArrows() {
        const {
            goRight, goLeft, children
        } = this.props;

        if (children.length > ITEMS_PER_ROW) {
            return (
                <>
                    <button
                      block="Slider"
                      elem="Arrow"
                      aria-label={ __('Previous') }
                      onClick={ goRight }
                      mods={ { isPrev: true } }
                    >
                        <ChevronIcon direction={ LEFT } />
                    </button>
                    <button
                      block="Slider"
                      elem="Arrow"
                      aria-label={ __('Next') }
                      mods={ { isNext: true } }
                      onClick={ goLeft }
                    >
                        <ChevronIcon direction={ RIGHT } />
                    </button>
                </>
            );
        }

        return null;
    }

    render() {
        const {
            isMobile
        } = this.props;

        if (isMobile) {
            return this.renderMobileSlider();
        }

        return this.renderDesktopSlider();
    }
}

export default ProductSliderComponent;

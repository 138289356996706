/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { MixType } from 'Type/Common.type';

import BlogPostCard from '../BlogPostCard';
import { NUMBER_OF_RELATED_POSTS } from './BlogRelatedPosts.config';

import './BlogRelatedPosts.style';

/** @namespace Blog/Component/BlogRelatedPosts/Component/BlogRelatedPostsComponent */
export class BlogRelatedPostsComponent extends PureComponent {
    static propTypes = {
        related_posts: PropTypes.array,
        isLoaded: PropTypes.number,
        mix: MixType,
        relatedPostsNumber: PropTypes.number.isRequired
    };

    static defaultProps = {
        related_posts: [],
        isLoaded: null,
        mix: {}
    };

    state = {
        isExpanded: false
    };

    toggleExpand = () => this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));

    renderRelatedPosts() {
        const { related_posts, isLoaded, relatedPostsNumber = NUMBER_OF_RELATED_POSTS } = this.props;

        if (!related_posts.length && !isLoaded) {
            return Array.from({ length: relatedPostsNumber }, (_, i) => (
                <BlogPostCard
                  key={ i }
                  isPlaceholder
                />
            ));
        }

        return related_posts.slice(0, relatedPostsNumber).map((post) => (
            <BlogPostCard
              key={ post.post_id }
              post={ post }
              mix={ { block: 'BlogRelatedPosts', elem: 'PostCard' } }
              isCompactOnMobile
            />
        ));
    }

    renderTitle() {
        const { related_posts } = this.props;
        const { isExpanded } = this.state;

        if (!related_posts.length) {
            return null;
        }

        return (
            <button block="BlogRelatedPosts" elem="Button" mods={ { isExpanded } } onClick={ this.toggleExpand }>
                <h3 block="BlogRelatedPosts" elem="Title">
                    { __('Related Posts') }
                </h3>
            </button>
        );
    }

    render() {
        const { related_posts, mix } = this.props;
        const { isExpanded } = this.state;

        if (related_posts.length < 1) {
            return null;
        }

        return (
            <div block="BlogRelatedPosts" elem="Wrapper" mix={ mix }>
                { this.renderTitle() }
                <div block="BlogRelatedPosts" elem="PostsWrapper" mods={ { isExpanded } }>
                    { this.renderRelatedPosts() }
                </div>
            </div>
        );
    }
}

export default BlogRelatedPostsComponent;

/* eslint-disable max-lines */
/* eslint-disable max-len */
/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aivars Arbidans <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Loader from 'Component/Loader';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { CustomerType } from 'Type/Account.type';
import { fetchMutation } from 'Util/Request';

import PrivacyInformationQuery from '../../query/PrivacyInformation.query';
import PrivacySettingsQuery from '../../query/PrivacySettings.query';
import { formatConsents } from '../../util/Privacy';
import { PRIVACY_SETTINGS_POPUP_ID } from '../MyAccountPrivacySettingsPopup/MyAccountPrivacySettingsPopup.config';
import MyAccountPrivacySettings from './MyAccountPrivacySettings.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Gdpr/Component/MyAccountPrivacySettings/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace Gdpr/Component/MyAccountPrivacySettings/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showPopup: (payload) => dispatch(showPopup(PRIVACY_SETTINGS_POPUP_ID, payload)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(null, true, dispatch)
    )
});

/** @namespace Gdpr/Component/MyAccountPrivacySettings/Container */
export class MyAccountPrivacySettingsContainer extends PureComponent {
    static propTypes = {
        customer: CustomerType.isRequired,
        showNotification: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        requestCustomerData: PropTypes.func.isRequired
    };

    state = {
        isLoading: true
    };

    containerFunctions = {
        downloadData: this.downloadData.bind(this),
        showConfirmPopupCSV: this.showConfirmPopupCSV.bind(this),
        showConfirmPopupDelete: this.showConfirmPopupDelete.bind(this),
        showConfirmPopupAnonymise: this.showConfirmPopupAnonymise.bind(this),
        updateConsentSelection: this.updateConsentSelection.bind(this),
        onSaveConsents: this.onSaveConsents.bind(this)
    };

    componentDidMount() {
        this.checkLoaded();
    }

    componentDidUpdate(prevProps) {
        this.checkLoaded(prevProps);
    }

    checkLoaded(prevProps = {}) {
        const { customer } = this.props;
        const { customer: prevCustomer } = prevProps;

        if (customer && !prevCustomer) {
            this.stopLoading();
        }
    }

    startLoading() {
        this.setState({ isLoading: true });
    }

    stopLoading() {
        this.setState({ isLoading: false });
    }

    showConfirmPopupCSV(isDownload = false, isAnonymise = false, isDelete = false) {
        const { showPopup } = this.props;

        showPopup({
            action: PRIVACY_SETTINGS_POPUP_ID,
            title: __('Download Personal Data'),
            isDownload,
            isAnonymise,
            isDelete,
            downloadData: this.downloadData.bind(this),
            anonymiseData: this.anonymiseData.bind(this),
            deleteData: this.deleteData.bind(this)
        });
    }

    showConfirmPopupAnonymise(isDownload = false, isAnonymise = false, isDelete = false) {
        const { showPopup } = this.props;

        showPopup({
            action: PRIVACY_SETTINGS_POPUP_ID,
            title: __('Anonymise personal data'),
            isDownload,
            isAnonymise,
            isDelete,
            downloadData: this.downloadData.bind(this),
            anonymiseData: this.anonymiseData.bind(this),
            deleteData: this.deleteData.bind(this)
        });
    }

    showConfirmPopupDelete(isDownload = false, isAnonymise = false, isDelete = false) {
        const { showPopup } = this.props;

        showPopup({
            action: PRIVACY_SETTINGS_POPUP_ID,
            title: __('Delete Account'),
            isDownload,
            isAnonymise,
            isDelete,
            downloadData: this.downloadData.bind(this),
            anonymiseData: this.anonymiseData.bind(this),
            deleteData: this.deleteData.bind(this)
        });
    }

    /**
     * Anonymize customers data
     *
     * @param password
     * @returns {Promise<void>}
     */
    async anonymiseData() {
        const { showNotification, requestCustomerData } = this.props;

        this.startLoading();

        const request = await fetchMutation(PrivacySettingsQuery.anonymiseCustomerData());

        const { anonymiseCustomerData } = request;

        if (!anonymiseCustomerData.result) {
            showNotification('error', anonymiseCustomerData.message);
            this.stopLoading();
            return;
        }

        showNotification('success', __('Anonymisation was successful'));

        requestCustomerData();
        this.stopLoading();
    }

    /**
     * Delete customers data
     *
     * @param password
     * @returns {Promise<void>}
     */
    async deleteData() {
        const { showNotification } = this.props;

        this.startLoading();

        const request = await fetchMutation(PrivacySettingsQuery.deleteCustomer());

        const { deleteCustomer } = request;

        if (!deleteCustomer.result) {
            showNotification('error', deleteCustomer.message);
            this.stopLoading();
            return;
        }

        showNotification('success', __('Thank you, your account delete request was recorded.'));
        this.stopLoading();
    }

    processRow(data) {
        const { key, value } = data;

        return `${ key }, ${ value }\n`;
    }

    /**
     * Download customers data
     *
     * @param password
     * @returns {Promise<void>}
     */
    async downloadData() {
        const { showNotification } = this.props;

        this.startLoading();

        const request = await fetchMutation(PrivacySettingsQuery.getCustomerPrivacyData());
        const { getCustomerData } = request;

        if (!getCustomerData.result.length) {
            showNotification('error', getCustomerData.message);
            this.stopLoading();
            return;
        }

        const data = getCustomerData.result;
        let csvFile = '';

        for (let i = 0; i < data.length; i++) {
            csvFile += this.processRow(data[i]);
        }

        const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, 'personal-data.csv');
        } else {
            const reader = new FileReader();
            reader.onloadend = () => {
                const a = document.createElement('a');
                a.href = reader.result;
                a.style.display = 'none';
                a.download = 'personal-data.csv';
                document.body.appendChild(a);
                a.click();
                a.parentNode.removeChild(a);
            };
            reader.readAsDataURL(blob);
        }

        showNotification('success', __('Your data has been successfully downloaded!'));
        this.stopLoading();
    }

    updateConsentSelection(consentSelection) {
        this.setState({ consentSelection });
    }

    async onSaveConsents() {
        const { consentSelection } = this.state;
        const { showNotification } = this.props;

        const formattedConsentSelection = formatConsents(consentSelection);

        this.startLoading();
        const query = PrivacyInformationQuery.getSaveConsentSettingsMutation(formattedConsentSelection, 'privacy_policy_popup');
        const response = await fetchMutation(query);
        this.stopLoading();

        if (response.updateConsents) {
            showNotification('success', __('Saved consents!'));
        }
    }

    render() {
        const { isLoading } = this.state;

        return (
            <>
                <Loader isLoading={ isLoading } />
                <MyAccountPrivacySettings
                  { ...this.containerFunctions }
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPrivacySettingsContainer);

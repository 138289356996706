/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import PrivacyPolicyPopup from './PrivacyPolicyPopup.component';
import { PRIVACY_POLICY_POPUP_ID } from './PrivacyPolicyPopup.config';

/** @namespace Gdpr/Component/PrivacyPolicyPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload,
    showOnFirstVisit: state.ConfigReducer.gdpr_first_visit_show
});

/** @namespace Gdpr/Component/PrivacyPolicyPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(PRIVACY_POLICY_POPUP_ID, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyPopup);

/**
 * @category  Scandiweb
 * @package   ScandiPWA/CatalogLoadMore
 * @author    Ramona Cunska <info@scandiweb.com>
 */

import { UPDATE_PRODUCT_LIST_ITEMS } from 'Store/ProductList/ProductList.action';

/**
 * Extended to fix issue when a new page gets preloaded on PLP and we change filters
 * before it is done loading. isPageLoading was stuck in true status even after the
 * request finished which caused "load more" functionality to break.
 */
const ProductListReducer = (args, callback) => {
    const [, action] = args;
    const { type } = action;
    const original = callback(...args);

    switch (type) {
    case UPDATE_PRODUCT_LIST_ITEMS:
        return {
            ...original,
            isPageLoading: false
        };
    default:
        return original;
    }
};

export default {
    'Store/ProductList/Reducer/ProductListReducer': {
        function: ProductListReducer
    }
};

/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aleksejs Ivanovs <info@scandiweb.com>
 */

export const PRIVACY_POLICY_POPUP_ID = 'PrivacyPolicyPopupComponent';
export const PRIVACY_POLICY_POPUP_FIRST_VISIT = 'PRIVACY_POLICY_POPUP_FIRST_VISIT';

/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Field from 'Component/Field';
import { CHECKOUT } from 'Component/Header/Header.config';
import Html from 'Component/Html';
import Loader from 'Component/Loader';
import { ChildrenType } from 'Type/Common.type';
import history from 'Util/History';

import { PrivacyCheckboxListType } from '../../type/Privacy';

import './PrivacyConsentCheckboxes.style.scss';

/** @namespace Gdpr/Component/PrivacyConsentCheckboxes/Component */
export class PrivacyConsentCheckboxesComponent extends PureComponent {
    static propTypes = {
        areaConsents: PrivacyCheckboxListType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isSatisfied: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        privacyPolicy: PropTypes.string.isRequired,
        isBoxAccepted: PropTypes.func.isRequired,
        setBoxAccepted: PropTypes.func.isRequired,
        onSaveConsents: PropTypes.func,
        children: ChildrenType
    };

    static defaultProps = {
        children: null,
        onSaveConsents: null
    };

    renderMustAcceptMessage() {
        return (
            <p block="PrivacyConsentCheckboxes" elem="SignUp">
                { __('You must accept the required conditions to continue.') }
            </p>
        );
    }

    renderAction() {
        const { children } = this.props;
        // Set default state to true to accept policy by default as it is not needed
        // right now for the client to be displayed on FE
        // ticket WHEY-396
        const isSatisfied = true;

        if (!isSatisfied) {
            return this.renderMustAcceptMessage();
        }

        if (!children) {
            return (
                <button
                  block="Button"
                >
                    { __('Save') }
                </button>
            );
        }

        return children;
    }

    renderConsent({
        code, text, name, isRequired
    }) {
        const { setBoxAccepted, privacyPolicy } = this.props;
        // const accepted = isBoxAccepted(code);
        const accepted = true;

        return (
            <li block="PrivacyConsentCheckboxes" elem="Consent" key={ code }>
                <Field
                  mix={ {
                      block: 'PrivacyConsentCheckboxes',
                      elem: 'Box'
                  } }
                  attr={ {
                      id: { code },
                      name: { name }
                  } }
                  type="checkbox"
                  value={ code }
                  checked={ accepted }
                  events={ { onChange: () => setBoxAccepted(code, !accepted) } }
                />
                <label
                  block="PrivacyConsentCheckboxes"
                  elem="BoxLabel"
                  htmlFor={ code }
                >
                    <Html content={ text } privacyPolicy={ privacyPolicy } />
                    { this.renderRequiredMessage(isRequired, accepted) }
                </label>
            </li>
        );
    }

    renderRequiredMessage(isRequired, accepted) {
        if (!isRequired || accepted) {
            return null;
        }

        return (
            <span block="PrivacyConsentCheckboxes" elem="RequiredMessage">*</span>
        );
    }

    render() {
        const { isDisabled, areaConsents, isLoading } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        if (isDisabled || !areaConsents.length) {
            return this.renderAction() || null;
        }

        return (
            <div block="PrivacyConsentCheckboxes">
                <div block="PrivacyConsentCheckboxes" elem="Wrapper">
                    <ul block="PrivacyConsentCheckboxes" elem="Consents">
                        { areaConsents.map((consent) => this.renderConsent(consent)) }
                    </ul>
                </div>
                { this.renderAction() }
            </div>
        );
    }
}

export default PrivacyConsentCheckboxesComponent;

/* eslint-disable max-len */
import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const StoreFinder = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "storeFinder" */ '../route/StoreFinder'));
export const StoreDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "StoreDetails" */ '../route/StoreDetails'));
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export class RouterPlugin {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    SWITCH_ITEMS_TYPE = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex('/stores') } exact component={ StoreFinder } />,
            position: 90
        },
        {
            component: <Route path={ withStoreRegex('/stores:/identifier') } component={ StoreDetails } />,
            position: 89
        }
    ];
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE
        }
    }
};

export default config;

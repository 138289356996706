import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import {
    CheckoutPayment as SourceCheckoutPayment
} from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import './CheckoutPayment.override.style';

/** @namespace Scandipwa/Component/CheckoutPayment/Component */
export class CheckoutPaymentComponent extends SourceCheckoutPayment {
    /**
     * Overridden to change checkboxes to radio
     */
    render() {
        const {
            isSelected,
            method: { title }
        } = this.props;

        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ this.onClick }
                >
                    <Field
                      type={ FIELD_TYPE.radio }
                      attr={ {
                          id: `option-${ title }`,
                          name: `option-${ title }`,
                          checked: isSelected
                      } }
                      label={ __(`${title}`) }
                    />
                </button>
            </li>
        );
    }
}

export default CheckoutPaymentComponent;

/* eslint-disable fp/no-let */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';
import Html from 'Component/Html';
import Popup from 'Component/Popup';

import { CookieInfoType, CookieSettingsType } from '../../type/GdprCookie';
import GdprCookieGroupItem from '../GdprCookieGroupItem';
import {
    GDPR_COOKIES_CLASSIC_STYLE, GDPR_COOKIES_POPUP_ID, GDPR_COOKIES_POPUP_STYLE, GDPR_COOKIES_SIDEBAR_STYLE
} from './GdprCookiePopup.config';

import './GdprCookiePopup.style';

/** @namespace GdprCookie/Component/GdprCookiePopup/Component/GdprCookiePopupComponent */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
export class GdprCookiePopupComponent extends PureComponent {
    static propTypes = {
        cookieGroups: PropTypes.arrayOf(CookieInfoType),
        handleCheckedGroups: PropTypes.func.isRequired,
        handleAcceptAllGroups: PropTypes.func.isRequired,
        handleAcceptCookies: PropTypes.func.isRequired,
        handleDeclineCookies: PropTypes.func.isRequired,
        handleCustomAction: PropTypes.func.isRequired,
        handleClosePopup: PropTypes.func.isRequired,
        cookieSettings: PropTypes.arrayOf(CookieSettingsType),
        barStyle: PropTypes.string,
        isCookieSet: PropTypes.bool,
        isCookieBarActive: PropTypes.bool,
        isStatic: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        code: PropTypes.string.isRequired
    };

    static defaultProps = {
        cookieGroups: [],
        cookieSettings: [],
        barStyle: GDPR_COOKIES_SIDEBAR_STYLE,
        isCookieSet: false,
        isCookieBarActive: false
    };

    renderCookieGroup = (cookieGroup) => {
        const { handleCheckedGroups } = this.props;
        const {
            groupId,
            isEssential,
            name,
            description,
            cookies
        } = cookieGroup;

        return (
            <GdprCookieGroupItem
              key={ `${groupId}-${name}` }
              groupId={ groupId }
              isEssential={ isEssential }
              name={ name }
              description={ description }
              cookies={ cookies }
              handleCheckedGroups={ handleCheckedGroups }
            />
        );
    };

    renderCookieGroups() {
        const { cookieGroups } = this.props;

        if (Object.keys(cookieGroups).length === 0) {
            return null;
        }

        return cookieGroups.reduce(
            (acc, e) => [...acc, this.renderCookieGroup(e)],
            []
        );
    }

    renderDeclineButton() {
        const { handleDeclineCookies } = this.props;
        const { cookieSettings: { gdpr_cookie_decline_enabled, gdpr_cookie_decline_button_text = '' } } = this.props;

        if (!gdpr_cookie_decline_enabled) {
            return null;
        }

        return (
            <button
              block="GdprCookiesPopup"
              elem="Decline"
              type="button"
              mix={ { block: 'Button', mods: { isHollow: true } } }
              onClick={ handleDeclineCookies }
            >
                { gdpr_cookie_decline_button_text || __('Decline Cookies') }
            </button>
        );
    }

    renderButtons() {
        const {
            handleAcceptAllGroups,
            handleAcceptCookies,
            handleCustomAction,
            cookieSettings: {
                gdpr_cookie_accept_button_text = '',
                gdpr_cookie_settings_button_text = '',
                gdpr_cookie_bar_style = ''
            },
            barStyle
        } = this.props;

        const isSidebar = barStyle === GDPR_COOKIES_SIDEBAR_STYLE;
        const isPopup = barStyle === GDPR_COOKIES_POPUP_STYLE;

        const allowHandler = isSidebar ? handleAcceptCookies : handleAcceptAllGroups;
        let allowAllHandler = handleCustomAction;
        let allowAllText = gdpr_cookie_settings_button_text;

        if (isPopup) {
            allowAllHandler = handleAcceptCookies;

            if (gdpr_cookie_bar_style !== GDPR_COOKIES_POPUP_STYLE) {
                allowAllText = __('Accept Cookies');
            }
        }

        if (isSidebar) {
            allowAllHandler = handleAcceptAllGroups;
            allowAllText = allowAllText || __('Allow All Cookies');
        }

        allowAllText = allowAllText || __('Accept Cookies');

        return (
            <div
              block="GdprCookiesPopup"
              elem="Buttons"
            >
                <button
                  block="GdprCookiesPopup"
                  elem={ isSidebar ? 'AllowAll' : 'Custom' }
                  type="button"
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                  onClick={ allowAllHandler }
                >
                    { allowAllText }
                </button>
                { (barStyle !== GDPR_COOKIES_CLASSIC_STYLE) && this.renderDeclineButton() }
                <button
                  block="GdprCookiesPopup"
                  elem="Allow"
                  type="button"
                  mix={ { block: 'Button' } }
                  onClick={ allowHandler }
                >
                    { gdpr_cookie_accept_button_text || __('Accept Cookies') }
                </button>
            </div>
        );
    }

    renderActions() {
        const { cookieSettings: { gdpr_cookie_bar_text }, code } = this.props;
        const isRussianLang = code.includes('ru');
        return (
            <div
              block="GdprCookiesPopup"
              elem="Actions"
            >
                <p
                  block="GdprCookiesPopup"
                  elem="Description"
                  mods={ { isRussian: isRussianLang } }
                >
                    <Html content={ gdpr_cookie_bar_text } />
                </p>
                { this.renderButtons() }
            </div>
        );
    }

    renderCloseButton() {
        const { handleClosePopup } = this.props;

        return (
            <header block="GdprCookiesPopup" elem="Header">
                <button
                  block="Popup"
                  elem="CloseBtn"
                  aria-label={ __('Close') }
                  onClick={ handleClosePopup }
                >
                    <CloseIcon />
                </button>
            </header>
        );
    }

    renderContent() {
        const {
            isLoading,
            cookieGroups,
            cookieSettings,
            barStyle
        } = this.props;

        if (isLoading || !cookieGroups || !cookieSettings) {
            return null;
        }

        const isClassicBar = barStyle === GDPR_COOKIES_CLASSIC_STYLE;

        return (
            <div
              block="GdprCookiesPopup"
              elem="Wrapper"
            >
                <div
                  block="GdprCookiesPopup"
                  elem="Groups"
                >
                    { !isClassicBar && this.renderCloseButton() }
                    { !isClassicBar && this.renderCookieGroups() }
                </div>
                { this.renderActions() }
            </div>
        );
    }

    render() {
        const {
            isCookieSet, isStatic, isCookieBarActive, cookieSettings, barStyle
        } = this.props;

        const isPopup = barStyle === GDPR_COOKIES_POPUP_STYLE;
        const isClassicBar = barStyle === GDPR_COOKIES_CLASSIC_STYLE;
        const isClassicBarTop = (cookieSettings || {})?.gdpr_cookie_bar_classic_location === '1';
        const isClassicBarBottom = (cookieSettings || {})?.gdpr_cookie_bar_classic_location === '0';

        if (isCookieSet || !isCookieBarActive) {
            return null;
        }

        return (
            <Popup
              id={ GDPR_COOKIES_POPUP_ID }
              clickOutside={ false }
              isStatic={ isStatic }
              mix={ {
                  block: 'GdprCookiesPopup',
                  mods: {
                      isPopup, isClassicBar, isClassicBarTop, isClassicBarBottom
                  }
              } }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default GdprCookiePopupComponent;

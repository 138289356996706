import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';

/** @namespace Scandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    /**
     * Overridden to fix PDP infinite load issue
     */
    componentDidMount() {
        this.requestUrlRewrite();

        this.initialUrl = location.pathname;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);

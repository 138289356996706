/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createElement } from 'react';

import WidgetFactory from 'Component/WidgetFactory';

import HtmlCode from '../../component/HtmlCode';
import { HTML_CODE_CONTENT_TYPE, HTML_CODE_SKELETON } from '../../component/HtmlCode/HtmlCode.config';

const addReplacementRule = (originalMember, instance) => ([
    ...originalMember,
    {
        query: { dataContentType: HTML_CODE_CONTENT_TYPE },
        replace: (domNode) => {
            if (domNode.children[0].name === 'widget') {
                return (
                    createElement(WidgetFactory, {
                        ...domNode.children[0].attribs
                    })
                );
            }

            return (
                createElement(HtmlCode, {
                    elements: instance.toReactElements(
                        [domNode],
                        HTML_CODE_SKELETON,
                        { allowedTypes: ['tag', 'script'] }
                    )
                })
            );
        }
    }
]);

export default {
    'Component/Html/Component': {
        'member-property': {
            rules: addReplacementRule
        }
    }
};

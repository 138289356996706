import { ChevronIcon } from 'Component/ChevronIcon/ChevronIcon.component';

import './SliderArrow.style';

/** @namespace Scandipwa/Component/SliderArrow/Component */
export class SliderArrowComponent extends ChevronIcon {
    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              mix={ { block: 'SliderArrow' } }
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M13 8l7.5 7.5L13 23" />
            </svg>
        );
    }
}

export default SliderArrowComponent;

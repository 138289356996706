/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';

import FIELD_TYPE from 'Component/Field/Field.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import history from 'Util/History';
import DataContainer from 'Util/Request/DataContainer';

import FlexibleFormsWidgetComponent from './FlexibleFormsWidget.component';
import { FLEXIBLEFORMS_FORM_HANDLER, RESPONSE_CODES, SUCCESS_POPUP_ID } from './FlexibleFormsWidget.config';

export const FlexibleFormsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/FlexibleForms/FlexibleForms.dispatcher'
);

/** @namespace Scandiweb/Flexibleforms/Component/FlexibleFormsWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    flexibleForms: state.FlexibleFormsReducer.flexibleForms,
    base_url: state.ConfigReducer.base_url,
    product_url: state.ProductReducer?.product?.url,
    dealer: state.ProductReducer?.product?.attributes?.dealer?.attribute_options
});

/** @namespace Scandiweb/Flexibleforms/Component/FlexibleFormsWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getFormData: (form_id) => FlexibleFormsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, form_id)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showPopup: (payload) => dispatch(showPopup(SUCCESS_POPUP_ID, payload))
});

/** @namespace Scandiweb/Flexibleforms/Component/FlexibleFormsWidget/Container */
export class FlexibleFormsWidgetContainer extends DataContainer {
    state = {
        isLoading: true,
        isSubmitLoading: false,
        isPageLoaded: false,
        form: {
            options: {},
            files: {},
            images: {},
            ratings: {},
            recaptcha: '',
            check: {},
            multiselect: {},
            groupCheck: {}
        },
        termsChecked: {},
        checkboxes: {}
    };

    __construct(props) {
        super.__construct(props);
    }

    containerProps() {
        const {
            flexibleForms, form_id, base_url, product_url, dealer
        } = this.props;
        const {
            page, isPageLoaded, isLoading, isSubmitLoading
        } = this.state;

        return {
            isLoading,
            isSubmitLoading,
            isPageLoaded,
            page,
            flexibleForms,
            form_id,
            containerState: this.state,
            base_url,
            product_url,
            dealer
        };
    }

    containerFunctions() {
        return {
            handleSelectOnChange: this.handleSelectOnChange.bind(this),
            handleFieldOnChange: this.handleFieldOnChange.bind(this),
            handleFieldPrefill: this.handleFieldPrefill.bind(this),
            handleCheckboxOnChange: this.handleCheckboxOnChange.bind(this),
            handleFileTypeOnChange: this.handleFileTypeOnChange.bind(this),
            handleRadioOnChange: this.handleRadioOnChange.bind(this),
            handleStarOnChange: this.handleStarOnChange.bind(this),
            handleRecaptchaonChange: this.handleRecaptchaonChange.bind(this),
            handleMultiSelectOnChange: this.handleMultiSelectOnChange.bind(this),
            handleOptionCheckboxOnChange: this.handleOptionCheckboxOnChange.bind(this),
            postFormData: this.postFormData.bind(this)
        };
    }

    componentDidMount() {
        const { getFormData, form_id } = this.props;
        getFormData(form_id).then(() => {
            this.setState({ isLoading: false });
        });
    }

    postFormData() {
        this.sendRequest();
    }

    /**
     * Creates a FormData object to hold the form submission that will be sent in the request
     */
    populateFormData() {
        const { form: stateForm } = this.state;

        const {
            flexibleForms: {
                getForm: {
                    enable_captcha,
                    form_key
                }
            },
            form_id
        } = this.props;

        const form = new FormData();

        form.append('form_id', form_id);
        form.append('form_key', form_key);

        // append checkbox options
        Object.keys(stateForm.check).forEach((element) => {
            const newKey = `options[${element}][]`;
            form.append(newKey, stateForm.check[element]);
        });

        // append simple options
        Object.keys(stateForm.options).forEach((element) => {
            const newKey = `options[${element}]`;
            form.append(newKey, stateForm.options[element]);
        });

        // append file options
        Object.keys(stateForm.files).forEach((element) => {
            const newKey = `file_options[${element}]`;
            form.append(newKey, stateForm.files[element]);
        });

        // append image options
        Object.keys(stateForm.images).forEach((element) => {
            const newKey = `image_options[${element}]`;
            form.append(newKey, stateForm.images[element]);
        });

        // append rating options
        Object.keys(stateForm.ratings).forEach((element) => {
            const newKey = `ratings[${element}]`;
            form.append(newKey, stateForm.ratings[element]);
        });

        // append multiselect options
        Object.keys(stateForm.multiselect).forEach((element) => {
            const newKey = `options[${element}][]`;
            if (stateForm.multiselect[element]) {
                stateForm.multiselect[element].forEach((option) => {
                    form.append(newKey, option);
                });
            }
        });

        // append checkbox group options
        Object.keys(stateForm.groupCheck).forEach((element) => {
            Object.keys(stateForm.groupCheck[element]).forEach((check) => {
                const newKey = `options[${element}][]`;
                form.append(newKey, stateForm.groupCheck[element][check]);
            });
        });

        // append captcha options
        if (enable_captcha == 1) {
            form.append('g-recaptcha-response', stateForm.recaptcha);
        }

        return form;
    }

    setSubmitLoader() {
        this.setState({ isSubmitLoading: true });
    }

    removeSubmitLoader() {
        this.setState({ isSubmitLoading: false });
    }

    /**
     * Sends post request using axios
     */
    sendRequest() {
        this.setSubmitLoader();

        const { showNotification, flexibleForms, showPopup } = this.props;

        const form = this.populateFormData();

        axios.post(FLEXIBLEFORMS_FORM_HANDLER, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            this.removeSubmitLoader();

            showPopup({});

            if (response.data.url != '') {
                history.push(response.data.url);
            }
        }).catch((error) => {
            this.removeSubmitLoader();

            if (flexibleForms?.getForm?.form_fail_description) {
                showNotification('error', flexibleForms.getForm.form_fail_description);
            }
            if (error.response.status === RESPONSE_CODES.badRequest) {
                showNotification('error', error.response.data.errors);
            } else {
                showNotification('error', error.response.data.message);
            }
        }).finally(() => {
            this.removeSubmitLoader();
            this.handleRecaptchaonChange('');
        });
    }

    /**
     * START field type handlers
     */
    handleRecaptchaonChange(value) {
        const form = { ...this.state.form };
        form.recaptcha = value;
        this.setState({ form });
    }

    handleStarOnChange(e) {
        const form = { ...this.state.form };
        form.ratings[e.target.name] = e.target.value;
        this.setState({ form });
    }

    handleRadioOnChange(e) {
        const form = { ...this.state.form };
        form.options[e.target.name] = e.target.value;
        this.setState({ form });
    }

    handleFieldOnChange(e, field) {
        const { id } = field;
        const form = { ...this.state.form };
        form.options[id] = e.target.value;
        this.setState({ form });
    }

    handleFieldPrefill(attr) {
        const { defaultValue, id } = attr;
        const form = { ...this.state.form };
        form.options[id] = defaultValue;
        this.setState({ form });
    }

    handleSelectOnChange(value, field) {
        const { id } = field;
        const form = { ...this.state.form };
        form.options[id] = value;
        this.setState({ form });
    }

    handleCheckboxOnChange(e, field) {
        const {
            id, checked, type, value, url = ''
        } = field;
        const form = { ...this.state.form };
        if (checked && form.check[id]) {
            form.check[id] = '';
        }
        if (!checked) {
            form.check[id] = value;
        }
        if (type === FIELD_TYPE.terms) {
            form.check[id] = url;
        }
        if (type === FIELD_TYPE.send) {
            form.options[id] = form.check[id] ? '1' : '0';
        }
        const checkboxes = { ...this.state.checkboxes };
        checkboxes[id] = !checked;
        this.setState({ checkboxes, form });
    }

    handleOptionCheckboxOnChange(e, field) {
        const {
            id, checked, value, name
        } = field;
        const form = { ...this.state.form };
        if (!form.groupCheck[name]) {
            form.groupCheck[name] = {};
        }
        if (checked) {
            form.groupCheck[name][id] = '';
        }
        if (!checked) {
            form.groupCheck[name][id] = value;
        }
        const checkboxes = { ...this.state.checkboxes };
        checkboxes[id] = !checked;
        this.setState({ checkboxes, form });
    }

    handleMultiSelectOnChange(ref) {
        const form = { ...this.state.form };
        form.multiselect[ref.id] = Array.from(ref.options).filter((option) => option.selected).map((option) => option.value);
        this.setState({ form });
    }

    handleFileTypeOnChange(e, field) {
        const { id } = field;
        const form = { ...this.state.form };

        if (field.type === FIELD_TYPE.file) {
            form.files[id] = field.fieldRef.files[0];
        } else {
            form.images[id] = field.fieldRef.files[0];
        }
        this.setState({ form });
    }
    /**
     * END field type handlers
     */

    render() {
        return (
            <FlexibleFormsWidgetComponent
              { ...this.containerProps() }
              { ...this.containerFunctions() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlexibleFormsWidgetContainer);

/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aivars Arbidans <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import MyAccountPrivacySettingsPopup from './MyAccountPrivacySettingsPopup.component';
import { PRIVACY_SETTINGS_POPUP_ID } from './MyAccountPrivacySettingsPopup.config';

/** @namespace Gdpr/Component/MyAccountPrivacySettingsPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload[PRIVACY_SETTINGS_POPUP_ID] || {}
});

/** @namespace Gdpr/Component/MyAccountPrivacySettingsPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace Gdpr/Component/MyAccountPrivacySettingsPopup/Container */
export class MyAccountPrivacySettingsPopupContainer extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        payload: PropTypes.shape({
            isDownload: PropTypes.bool,
            downloadData: PropTypes.func,
            isAnonymise: PropTypes.bool,
            anonymiseData: PropTypes.func,
            isDelete: PropTypes.bool,
            deleteData: PropTypes.func
        }).isRequired
    };

    containerFunctions = {
        onSuccess: this.onSuccess.bind(this)
    };

    async onSuccess() {
        const { hideActiveOverlay, payload } = this.props;
        const {
            isDownload,
            downloadData,
            isAnonymise,
            anonymiseData,
            isDelete,
            deleteData
        } = payload;

        if (isDownload) {
            downloadData();
        }

        if (isAnonymise) {
            anonymiseData();
        }

        if (isDelete) {
            deleteData();
        }

        hideActiveOverlay();
    }

    render() {
        return (
            <MyAccountPrivacySettingsPopup
              { ...this.containerFunctions }
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPrivacySettingsPopupContainer);

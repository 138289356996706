/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-indent-props */
import PropTypes from 'prop-types';

import {
    FieldSelect as SourceFieldSelect
} from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Scandipwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    static propTypes = {
        fieldValue: PropTypes.string.isRequired
    };

    /**
     * Overridden to make label translatable
     */
    renderNativeOption(option) {
        const {
            id,
            value,
            disabled,
            label,
            subLabel = '',
            isAvailable = true
        } = option;

        const { isDisabled } = this.props;

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled || isDisabled || !isAvailable }
            >
                {subLabel ? __(`${label} ${subLabel}`) : __(`${label}`) }
            </option>
        );
    }

    /**
     * Overridden to add isSelected mod
     */
    renderNativeSelect() {
        const {
            setRef, attr, events, isDisabled, fieldValue, options, handleSelectListOptionClick
        } = this.props;

        const { defaultValue } = attr;

        const isSelected = !!(defaultValue || fieldValue);

        return (
            <select
                block="FieldSelect"
                elem="Select"
                mods={{ isSelected }}
                ref={(elem) => setRef(elem)}
                disabled={isDisabled}
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...attr}
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...events}
                onChange={handleSelectListOptionClick}
            >
                {options.map(this.renderNativeOption.bind(this))}
            </select>
        );
    }

    /**
     * Overridden to make label translatable
     */
    renderOption(option) {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered
              } }
              key={ id }
              /**
               * Added 'o' as querySelector does not work with
               * ids, that consist of numbers only
               */
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { __(label) }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    }

    /**
     * Overridden to make options scrollable
     */
    renderOptions() {
        const {
            options,
            isExpanded
        } = this.props;

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ { isExpanded } }
            >
                <div block="FieldSelectOptions" elem="Wrapper">
                    { options.map(this.renderOption.bind(this)) }
                </div>
            </ul>
        );
    }
}

export default FieldSelectComponent;

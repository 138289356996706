/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireProductDetailsEvent } from '../../event/product';

const addFireProductDetailEvent = (args, callback, instance) => {
    callback(...args);

    const { productSKU, product } = instance.props;

    // prevent event firing more than once
    if (product?.sku === productSKU) {
        fireProductDetailsEvent(product);
    }
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            updateMeta: addFireProductDetailEvent
        }
    }
};

/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { getCustomerData } from '../data/customer';
import { getPageData } from '../data/page';
import { getStoreData } from '../data/store';
import { GTM_EVENT_KEY_GENERAL } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/GoogleTagManager/Event/General/fireGeneralEvent */
export const fireGeneralEvent = debounceCallback(async () => {
    const {
        CookiesReducer: {
            cookieInfo: {
                isCookieSet
            } = {}
        } = {}
    } = getStore().getState();

    /**
     * This is added to prevent the general event
     * from getting queued and firing twice
     * see push.js for the queue
     */
    if (isCookieSet) {
        pushToDataLayer({
            event: GTM_EVENT_KEY_GENERAL,
            ...await getPageData(),
            ...await getStoreData(),
            ...await getCustomerData()
        });
    }
});

/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const POSTS_LISTING = 'posts-listing';
export const SOCIAL_PROVIDERS = {
    facebook: 'Facebook',
    twitter: 'Twitter',
    instagram: 'Instagram'
};

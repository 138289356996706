export const _getUrlResolverFields = (args, callback) => [
    ...callback(...args),
    'identifier'
];

export default {
    'Query/UrlRewrites/Query': {
        'member-function': {
            _getUrlResolverFields
        }
    }
};

import {
    FieldGroup as SourceFieldGroup
} from 'SourceComponent/FieldGroup/FieldGroup.component';

import './FieldGroup.override.style';

/** @namespace Scandipwa/Component/FieldGroup/Component */
export class FieldGroupComponent extends SourceFieldGroup {
    // TODO implement logic
}

export default FieldGroupComponent;

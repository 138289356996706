import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { EventsType, FieldAttrType, FieldOptionsType } from 'Type/Field.type';

import './FieldMultiselect.style.scss';

/**
  * Field Select
  * @class FieldMultiSelect
  * @namespace Scandiweb/Flexibleforms/Component/FieldMultiselect/Component */
export class FieldMultiselectComponent extends PureComponent {
     static propTypes = {
         attr: FieldAttrType.isRequired,
         events: EventsType.isRequired,
         options: FieldOptionsType.isRequired,
         setRef: PropTypes.func.isRequired,
         handleSelectListOptionClick: PropTypes.func.isRequired,
         isDisabled: PropTypes.bool.isRequired
     };

     renderNativeOption(option) {
         const {
             id,
             value,
             disabled,
             label,
             subLabel = '',
             isAvailable = true
         } = option;

         const { isDisabled } = this.props;

         return (
             <option
               key={ id }
               id={ id }
               value={ value }
               disabled={ disabled || isDisabled || !isAvailable }
             >
                 { `${label} ${subLabel}` }
             </option>
         );
     }

     renderNativeSelect() {
         const {
             setRef, attr, events, isDisabled, options, handleSelectListOptionClick
         } = this.props;

         return (
             <select
               block="FieldMultiSelect"
               elem="Select"
               ref={ (elem) => setRef(elem) }
               disabled={ isDisabled }
               // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
               { ...attr }
               // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
               { ...events }
               onChange={ handleSelectListOptionClick }
             >
                 { options.map(this.renderNativeOption.bind(this)) }
             </select>
         );
     }

     renderOption(option) {
         const {
             id,
             label,
             subLabel,
             isPlaceholder = false,
             isHovered,
             isAvailable = true
         } = option;

         const {
             handleSelectListOptionClick
         } = this.props;

         return (
             <li
               block="FieldMultiSelect"
               elem="Option"
               mods={ {
                   isDisabled: !isAvailable,
                   isPlaceholder,
                   isHovered
               } }
               key={ id }
               id={ `o${id}` }
               role="menuitem"
               // eslint-disable-next-line react/jsx-no-bind
               onMouseDown={ () => handleSelectListOptionClick(option) }
               // eslint-disable-next-line react/jsx-no-bind
               onTouchStart={ () => handleSelectListOptionClick(option) }
             >
                 { label }
                 { subLabel && (
                     <strong>
                         { ` ${subLabel}` }
                     </strong>
                 ) }
             </li>
         );
     }

     renderOptions() {
         const {
             options
         } = this.props;

         return (
             <ul
               block="FieldMultiSelect"
               elem="Options"
               role="menu"
             >
                 { options.map(this.renderOption.bind(this)) }
             </ul>
         );
     }

     render() {
         const {
             attr: { id = '' } = {}
         } = this.props;

         return (

                 <div
                   id={ `${ id }_wrapper` }
                   block="FieldMultiSelect"
                 >
                     <div block="FieldMultiSelect" elem="Clickable">
                         { this.renderNativeSelect() }
                     </div>

                 </div>

         );
     }
}

export default FieldMultiselectComponent;

/* eslint-disable react/boolean-prop-naming */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable max-lines */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import {
    EventsType,
    FieldAttrType,
    FieldOptionsType
} from 'Type/Field.type';

import FieldMultiselect from './FieldMultiselect.component';

/**
  * @class FieldMultiselectContainer
  * @namespace Scandiweb/Flexibleforms/Component/FieldMultiselect/FieldMultiselect/Container */
export class FieldMultiselectContainer extends PureComponent {
     static propTypes = {
         // Field attributes
         attr: FieldAttrType.isRequired,
         events: EventsType.isRequired,
         options: FieldOptionsType.isRequired,
         setRef: PropTypes.func.isRequired,
         isDisabled: PropTypes.bool.isRequired,
         noPlaceholder: PropTypes.bool
     };

     static defaultProps = {
         noPlaceholder: false
     };

     containerFunctions = {
         handleSelectListOptionClick: this.handleSelectListOptionClick.bind(this),
         setRef: this.setRef.bind(this)
     };

     fieldRef = createRef();

     setRef(elem) {
         const { setRef } = this.props;
         setRef(elem);

         if (elem && this.fieldRef !== elem) {
             this.fieldRef = elem;
         }
     }

     getOptions() {
         const {
             options
         } = this.props;

         return options;
     }

     handleSelectListOptionClick() {
         const { events: { onChange } = {} } = this.props;

         if (onChange) {
             onChange(this.fieldRef);
         }
     }

     isSelectDisabled() {
         const { options } = this.props;

         return !options.length;
     }

     updateValue(valueIndex) {
         if (this.fieldRef) {
             const { options } = this.props;
             const { value } = options[valueIndex];

             if (value) {
                 this.fieldRef.value.push(value);
             }
         }
     }

     containerProps() {
         const {
             attr: {
                 autoComplete,
                 autocomplete,
                 ...attr
             } = {},
             events,
             setRef,
             isDisabled
         } = this.props;

         return {
             attr: {
                 ...attr,
                 autoComplete: autoComplete || autocomplete,
                 multiple: 'multiple'
             },
             events,
             setRef,
             isDisabled,
             options: this.getOptions()
         };
     }

     render() {
         return (
             <FieldMultiselect
               { ...this.containerProps() }
               { ...this.containerFunctions }
             />
         );
     }
}

export default FieldMultiselectContainer;

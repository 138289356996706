/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import PropTypes from 'prop-types';

import { ALL, AREAS } from '../util/Privacy';

export const PrivacyCheckboxAreaType = PropTypes.oneOf([...AREAS, ALL]);

export const PrivacyCheckboxType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    isAgreed: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired
});

export const PrivacyCheckboxListType = PropTypes.arrayOf(PrivacyCheckboxType);

export const AllPrivacyConsentsType = PropTypes.shape(
    AREAS.reduce((previous, area) => ({
        ...previous,
        [area]: PrivacyCheckboxListType
    }))
);

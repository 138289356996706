import { FlexibleFormsReducer } from '../store/FlexibleForms/FlexibleForms.reducer';

const getStaticReducers = (args, callback) => ({
    ...callback(...args),
    FlexibleFormsReducer
});

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import FIELD_TYPE from 'Component/Field/Field.config';

const containerProps = (args, callback, instance) => {
    const { renderInlineButton } = instance.props;

    if (renderInlineButton) {
        return {
            ...callback(...args),
            renderInlineButton
        };
    }

    return {
        ...callback(...args)
    };
};

const renderDefaultInput = (args, callback, instance) => {
    const {
        attr: { value }, hasValue, type, setRef, attr, events, isDisabled
    } = instance.props;

    if (value === '' && hasValue) {
        return (
            <input
              ref={ (elem) => setRef(elem) }
              block="Field"
              elem="DefaultInput"
              mods={ { hasValue: false } }
              disabled={ isDisabled }
              type={ type }
              { ...attr }
              { ...events }
            />
        );
    }

    return callback(...args);
};

const renderTextArea = (args, callback, instance) => {
    const {
        attr: { value }, setRef, attr, events, isDisabled, hasValue, renderInlineButton
    } = instance.props;

    if (value === '' && hasValue) {
        return (
            <div block="Field" elem="ContentWrapper" mods={ { isWithInlineButton: true } }>
                { instance.renderLabel() }
                <textarea
                  block="Field"
                  elem="DefaultInput"
                  mods={ { hasValue: false } }
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  { ...attr }
                  { ...events }
                />
            </div>
        );
    }

    return (
        <div block="Field" elem="ContentWrapper" mods={ { isWithInlineButton: true } }>
            { instance.renderLabel() }
            <textarea
              block="Field"
              elem="DefaultInput"
              mods={ { hasValue } }
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              { ...attr }
              { ...events }
            />
        </div>
    );
};

const render = (args, callback, instance) => {
    const { type, validationResponse, mix } = instance.props;

    if (type === FIELD_TYPE.textarea) {
        const inputRenderer = instance.renderMap[type];
        return (
            <div block="Field" elem="Wrapper" mods={ { type } }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { inputRenderer && inputRenderer() }
                </div>
                { instance.renderErrorMessages() }
                { instance.renderSubLabel() }
            </div>
        );
    }

    return callback(...args);
};

export default {
    'Component/Field/Component': {
        'member-function': {
            renderDefaultInput,
            renderTextArea,
            render
        }
    },
    'Component/Field/Container': {
        'member-function': {
            containerProps
        }
    }
};

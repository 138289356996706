import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import ImageZoomIcon from 'Component/ImageZoomIcon';
import SliderArrow from 'Component/SliderArrow';
import {
    Slider as SourceSlider
} from 'SourceComponent/Slider/Slider.component';
import { noopFn } from 'Util/Common';

import './Slider.override.style';

/** @namespace Scandipwa/Component/Slider/Component */
export class SliderComponent extends SourceSlider {
    static defaultProps = {
        activeImage: 0,
        onActiveImageChange: noopFn,
        showCrumbs: false,
        showArrows: true,
        isInteractionDisabled: false,
        mix: {},
        onClick: null,
        isVertical: false,
        isHeightTransitionDisabledOnMount: false,
        sliderHeight: null,
        sliderRef: null
    };

    /**
     * Overridden to use different arrows
     * Overridden to add image zoom icon
     * FETA-540 Overridden to create a conditional for ZoomImageButton
     */
    renderArrows() {
        const {
            showArrows, activeImage, children
        } = this.props;
        const nextIsDisabled = activeImage + 1 === children.length;
        const prevIsDisabled = activeImage === 0;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                >
                    <SliderArrow direction={ LEFT } />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                >
                    <SliderArrow direction={ RIGHT } />
                </button>
                { this.renderImageZoomButton() }
            </>
        );
    }

    /**
     * Created to increase readability of code and implement logic of rendering button
     * @returns ImageZoomIcon
     */
    renderImageZoomButton() {
        const {
            handleSliderClick,
            activeImage,
            children
        } = this.props;
        const { props: { onZoomedVideoClick } = {} } = children[activeImage] || {};
        const isVideoSlideActive = !!onZoomedVideoClick;

        if (isVideoSlideActive) {
            return null;
        }

        return (
            <button
              block="Slider"
              elem="ImageZoomIcon"
              aria-label={ __('Image zoom') }
              onClick={ handleSliderClick }
            >
                <ImageZoomIcon />
            </button>
        );
    }
}

export default SliderComponent;

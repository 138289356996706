/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 */

import {
    ACCOUNT_FORGOT_PASSWORD,
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    CART,
    CHANGE_PASSWORD,
    CONFIRM_ACCOUNT,
    COOKIE_POPUP,
    CREATE_ACCOUNT,
    DEMO_NOTICE,
    FOOTER,
    HEADER,
    LOGIN,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_WISHLIST,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
    SHARED_WISHLIST,
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';
/**
 * Had to use a different name for router config file
 * due to importing issues
 */
import StickyFooter from 'Component/StickyFooter';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    PasswordChangePage,
    ProductComparePage,
    Router as SourceRouter,
    SearchPage,
    SomethingWentWrong,
    StyleGuidePage,
    WishlistShared,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';

import { STICKY_FOOTER } from './Router2.config';

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    SearchPage,
    MyAccount,
    PasswordChangePage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    ContactPage,
    ProductComparePage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    WishlistShared,
    withStoreRegex
};

/** @namespace Scandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    removedRoutes = [
        ACCOUNT_FORGOT_PASSWORD,
        CART,
        CHANGE_PASSWORD,
        CONFIRM_ACCOUNT,
        CREATE_ACCOUNT,
        LOGIN,
        MY_ACCOUNT,
        MY_ACCOUNT_ADDRESS,
        MY_ACCOUNT_DOWNLOADABLE,
        MY_ACCOUNT_NEWSLETTER,
        MY_ACCOUNT_ORDER,
        MY_ACCOUNT_ORDERS,
        MY_ACCOUNT_WISHLIST,
        SWITCH_ITEMS_TYPE,
        SHARED_WISHLIST
    ];

    /**
     * Overridden to remove NavigationTabs
     */
    [BEFORE_ITEMS_TYPE] = [
        {
            component: <NotificationList />,
            position: 10,
            name: NOTIFICATION_LIST
        },
        {
            component: <DemoNotice />,
            position: 15,
            name: DEMO_NOTICE
        },
        {
            component: <Header />,
            position: 20,
            name: HEADER
        },
        {
            component: <Breadcrumbs />,
            position: 30,
            name: BREADCRUMBS
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP
        }
    ];

    /**
     * Overridden to remove account, cart and wishlist pages
     */
    [SWITCH_ITEMS_TYPE] = this[SWITCH_ITEMS_TYPE].filter(
        ({ name }) => !this.removedRoutes.includes(name)
    );

    /**
     * Overridden to render StickyFooter on mobile
     */
    [AFTER_ITEMS_TYPE] = [
        {
            component: <StickyFooter isVisibleOnMobile />,
            position: 5,
            name: STICKY_FOOTER
        },
        {
            component: <Footer isVisibleOnMobile />,
            position: 10,
            name: FOOTER
        },
        {
            component: <CookiePopup />,
            position: 20,
            name: COOKIE_POPUP
        }
    ];
}

export default RouterComponent;

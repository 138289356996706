import { PureComponent } from 'react';

import './ImageZoomIcon.style';

/** @namespace Scandipwa/Component/ImageZoomIcon/Component */
export class ImageZoomIconComponent extends PureComponent {
    render() {
        return (
            <svg
              block="ImageZoomIcon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M15.75 4.5H19.5V8.25" />
                <path d="M14.25 9.75L19.5 4.5" />
                <path d="M8.25 19.5H4.5V15.75" />
                <path d="M9.75 14.25L4.5 19.5" />
                <path d="M19.5 15.75V19.5H15.75" />
                <path d="M14.25 14.25L19.5 19.5" />
                <path d="M4.5 8.25V4.5H8.25" />
                <path d="M9.75 9.75L4.5 4.5" />
            </svg>
        );
    }
}

export default ImageZoomIconComponent;

/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCartItemsData } from './cart';
import { CURRENCY_CODE } from './common';
import { DL_VAL_PAGE_BILLING, DL_VAL_PAGE_CHECKOUT, DL_VAL_PAGE_SHIPPING } from './page';

export const DL_VAL_CHECKOUT_SHIPPING_STEP = 1;
export const DL_VAL_CHECKOUT_BILLING_STEP = 2;

/** @namespace Scandiweb/Gtm/Data/Checkout/getCheckoutEventData */
export const getCheckoutEventData = async (step) => ({
    ecommerce: {
        currencyCode: CURRENCY_CODE,
        checkout: {
            actionField: {
                step,
                option: (step === 1) ? DL_VAL_PAGE_SHIPPING : DL_VAL_PAGE_BILLING
            },
            products: await getCartItemsData()
        }
    }
});

/** @namespace Scandiweb/Gtm/Data/Checkout/getCheckoutOptionEventData */
export const getCheckoutOptionEventData = async (step, option) => ({
    ecommerce: {
        checkout_option: {
            actionField: {
                step,
                option,
                action: DL_VAL_PAGE_CHECKOUT
            }
        }
    }
});

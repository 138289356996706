import { ERROR_TYPE } from 'Component/Notification/Notification.config';
import { deleteAuthorizationToken, isSignedIn } from 'Util/Auth';

export const aroundComponentDidMount = (args, callback, instance) => {
    const { notification: { msgType, msgText } } = instance.props;

    // Workaround to logout customer if account was deleted by admin
    if (msgType.toLowerCase() === ERROR_TYPE) {
        if (msgText && msgText.toLowerCase().includes('customer') && msgText.toLowerCase().includes('authorized')) {
            deleteAuthorizationToken();
            isSignedIn();
        }
    }

    callback.apply(instance, args);
};

export default {
    'Component/Notification/Component': {
        'member-function': {
            componentDidMount: aroundComponentDidMount
        }
    }
};

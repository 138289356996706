import {
    VALIDATION_INPUT_TYPE,
    VALIDATION_MESSAGES as sourceValidationMessages,
    VALIDATION_RULES as sourceValidationRules
} from 'SourceUtil/Validator/Config';

export * from 'SourceUtil/Validator/Config';

export const VALIDATION_MESSAGES = {
    ...sourceValidationMessages,
    [VALIDATION_INPUT_TYPE.phone]: __('Please insert telephone number with country code')
};

export const VALIDATION_RULES = {
    ...sourceValidationRules,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+][(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im
};

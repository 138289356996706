/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

export const CookiesType = PropTypes.shape({
    cookies: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        lifetime: PropTypes.number,
        name: PropTypes.string
    }))
});

export const CookieInfoType = PropTypes.shape({
    groupId: PropTypes.string,
    isEssential: PropTypes.bool,
    name: PropTypes.string,
    description: PropTypes.string,
    cookies: PropTypes.arrayOf(CookiesType)
});

export const CookieSettingsType = PropTypes.shape({
    gdpr_cookie_bar_text: PropTypes.string,
    gdpr_cookie_first_visit: PropTypes.bool,
    gdpr_cookie_lifetime: PropTypes.number,
    gdpr_cookie_decline_enabled: PropTypes.bool
});

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { getPrice as sourceGetPrice } from 'SourceUtil/Product/Extract';
import { formatPrice } from 'Util/Price';

export * from 'SourceUtil/Product/Extract';

/** @namespace Scandipwa/Util/Product/Extract/getPrice */
export const getPrice = (
    priceRange,
    dynamicPrice = false,
    adjustedPrice = {},
    type = PRODUCT_TYPE.simple,
    options = []
) => {
    const result = sourceGetPrice(priceRange, dynamicPrice, adjustedPrice, type, options);

    const {
        minimum_price: {
            reservation_price: {
                value: reservationValue = 0
            } = {},
            transportation_price: {
                value: transportationValue = 0
            } = {},
            registration_price: {
                value: registrationValue = 0
            } = {}
        } = {}
    } = priceRange;

    const {
        price: {
            finalPrice: {
                currency
            }
        },
        price
    } = result;

    price.reservationPrice = {
        value: reservationValue,
        valueFormatted: formatPrice(reservationValue, currency),
        currency
    };

    price.transportationPrice = {
        value: transportationValue,
        valueFormatted: formatPrice(transportationValue, currency),
        currency
    };

    price.registrationPrice = {
        value: registrationValue,
        valueFormatted: formatPrice(registrationValue, currency),
        currency
    };

    return result;
};

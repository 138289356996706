/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CategoriesReducer from '../store/Categories/Categories.reducer';
import PostsReducer from '../store/Posts/Posts.reducer';
import PostsDetailsReducer from '../store/PostsDetails/PostsDetails.reducer';
import PostsSearchReducer from '../store/PostsSearch/PostsSearch.reducer';
import TagsReducer from '../store/Tags/Tags.reducer';

export const aroundGetStaticReducers = (args, callback) => ({
    ...callback(...args),
    CategoriesReducer,
    PostsDetailsReducer,
    PostsReducer,
    PostsSearchReducer,
    TagsReducer
});

export default {
    'Store/Index/getStaticReducers': {
        function: [aroundGetStaticReducers]
    }
};

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BlogProductRelatedPosts from '../component/BlogProductRelatedPosts';

export const RELATED_POSITION = 3;

export const RELATED_POST_ATTR_CODE = 'product_related_blog_post';

export const renderAdditionalSections = (args, callback, instance) => {
    const { dataSource: { attributes = {} } } = instance.props;
    const { [RELATED_POST_ATTR_CODE]: { attribute_value: postIdsString = '' } = {} } = attributes;
    const postIds = postIdsString?.length ? postIdsString.split(',') : [];
    const parentElement = callback.apply(instance, args);
    const { children } = parentElement.props;
    const newElement = children.map((item) => item);
    newElement.splice(RELATED_POSITION, 0,
            <BlogProductRelatedPosts
              postIds={ postIds }
              mix={ { block: 'ProductPage', elem: 'RelatedPosts' } }
            />);

    return newElement;
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderAdditionalSections
        }
    }
};

import ProductSlider from 'Component/ProductSlider';

const render = (args, callback, instance) => {
    const { products } = instance.props;

    return (
        <section
          block="NewProducts"
          ref={ instance.newProductsRef }
        >
            <h1
              block="NewProducts"
              elem="Heading"
            >
            { __('Cars you might be interested in') }

            </h1>
            <ul block="NewProducts" elem="Products">
                <ProductSlider>
                    { products.map(instance.renderProductCard) }
                </ProductSlider>
            </ul>
        </section>
    );
};

export default {
    'Component/NewProducts/Component': {
        'member-function': {
            render
        }
    }
};

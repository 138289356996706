/* eslint-disable fp/no-let */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import parse from 'html-react-parser';

import Link from 'Component/Link';
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import FieldMultiselect from '../../../packages/@scandipwa/flexibleforms/src/component/FieldMultiselect/index';
import { FIELD_TYPE } from './Field.config';

import './Field.override.style';

/** @namespace Scandipwa/Component/Field/Component */
export class FieldComponent extends SourceField {
    /**
     * Overridden to change method of rendering password field
     * JAID-73 - Overridden to add flexible form fields
     */
    renderMap = {

        ...this.renderMap,

        // Flexible form fields
        [FIELD_TYPE.image]: this.renderFile.bind(this),
        [FIELD_TYPE.url]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.state]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.country]: this.renderSelect.bind(this),
        [FIELD_TYPE.terms]: this.renderTerms.bind(this),
        [FIELD_TYPE.multiselect]: this.renderMultiSelect.bind(this),
        [FIELD_TYPE.send]: this.renderTerms.bind(this)
        // [FIELD_TYPE.star]: this.renderStar.bind(this)

    };

    /**
     * JAID-73 - Added multi select field
     */
    renderMultiSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false
        } = this.props;

        return (
            <FieldMultiselect
              attr={ attr }
              events={ events }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
            />
        );
    }

    /**
     * Overridden to add span to style disabled labels
     */
    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr,
            attr: { id = '' } = {},
            events: { onChange },
            events,
            isDisabled,
            label
        } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ type }
                  { ...attr }
                  { ...inputEvents }
                />
                <div block="input-control" />
                <span>{ label }</span>
            </label>
        );
    }

    /**
     * JAID-73 - Added Terms and conditions field
     */
    renderTerms() {
        const {
            setRef,
            attr,
            attr: {
                id = '',
                url
            } = {},
            events: {
                onChange
            },
            events,
            type,
            isDisabled
        } = this.props;

        const newType = FIELD_TYPE.checkbox;
        const elem = newType.charAt(0).toUpperCase() + newType.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };

        const renderTermsLabel = () => {
            if (type === FIELD_TYPE.terms) {
                return parse(url, { replace: this.renderLink });
            }

            return (
                <>
                { __('Send copy to me') }
                </>
            );
        };

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ newType }
                  { ...attr }
                  { ...inputEvents }
                />
                <div block="input-control" />
                <div block="Field" elem="Link">{ renderTermsLabel() }</div>
            </label>
        );
    }

    renderLink = (domNode) => {
        const {
            attribs: {
                href
            } = {},
            children: [{
                data: text
            }] = [{}]
        } = domNode;

        if (!href) {
            return null;
        }

        return <Link to={ href }>{ text }</Link>;
    };

    render() {
        const { validationResponse, mix } = this.props;
        let { type } = this.props;
        const inputRenderer = this.renderMap[type];
        if (type === FIELD_TYPE.terms || type === FIELD_TYPE.send) {
            type = FIELD_TYPE.checkbox;
        }

        return (
            <div block="Field" elem="Wrapper" mods={ { type } }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { type !== FIELD_TYPE.terms && type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && type !== FIELD_TYPE.send && this.renderLabel() }
                    { inputRenderer && inputRenderer() }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}

export default FieldComponent;

const onShippingSuccess = (args, callback) => {
    const fields = args[1];

    fields.push(
        {
            name: 'country_id',
            value: 'LV'
        },
        {
            name: 'region_id',
            value: '362'
        },
        {
            name: 'city',
            value: 'Riga'
        },
        {
            name: 'street_0',
            value: 'Riga'
        },
        {
            name: 'postcode',
            value: '1324'
        }
    );

    return callback(...args);
};

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess
        }
    }
};

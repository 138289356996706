/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MixType } from 'Type/Common.type';

import { updatePosts } from '../../store/Posts/Posts.action';
import BlogRelatedPostsComponent from '../BlogRelatedPosts/BlogRelatedPosts.component';
import { NUMBER_OF_RELATED_POSTS } from '../BlogRelatedPosts/BlogRelatedPosts.config';

export const PostsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/Posts/Posts.dispatcher'
);

/** @namespace Blog/Component/BlogProductRelatedPosts/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    posts: state.PostsReducer.posts,
    isLoaded: state.PostsReducer.isLoaded,
    relatedPostsNumber: state.ConfigReducer.mfblog_product_page_number_of_related_posts || NUMBER_OF_RELATED_POSTS
});

/** @namespace Blog/Component/BlogProductRelatedPosts/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestPosts: (options) => PostsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    clearPosts: () => dispatch(updatePosts({ items: [], total_count: 0 }))
});

/** @namespace Blog/Component/BlogProductRelatedPosts/Container/BlogProductRelatedPostsContainer */
export class BlogProductRelatedPostsContainer extends PureComponent {
    static propTypes = {
        posts: PropTypes.array.isRequired,
        isLoaded: PropTypes.bool.isRequired,
        clearPosts: PropTypes.func.isRequired,
        requestPosts: PropTypes.func.isRequired,
        relatedPostsNumber: PropTypes.number.isRequired,
        postIds: PropTypes.arrayOf(PropTypes.number).isRequired,
        mix: MixType
    };

    static defaultProps = {
        mix: {}
    };

    componentDidMount() {
        const { postIds } = this.props;

        if (!postIds.length) {
            return;
        }

        this.requestPosts();
    }

    componentDidUpdate(prevProps) {
        const { postIds } = this.props;
        const { postIds: prevPostIds } = prevProps;

        if (postIds.length === prevPostIds.length
            && postIds.every((value, index) => value === prevPostIds[index])) {
            return;
        }

        this.requestPosts();
    }

    componentWillUnmount() {
        const { clearPosts } = this.props;

        clearPosts();
    }

    containerProps() {
        const { posts: { items }, isLoaded, relatedPostsNumber } = this.props;

        return {
            related_posts: items,
            isLoaded,
            relatedPostsNumber
        };
    }

    requestPosts() {
        const { requestPosts, postIds, relatedPostsNumber } = this.props;

        requestPosts({
            filter: { post_id: { in: postIds } },
            pageSize: relatedPostsNumber,
            getDescription: true,
            getMedia: true
        });
    }

    render() {
        const { mix } = this.props;

        return (
            <BlogRelatedPostsComponent
              { ...this.containerProps() }
              mix={ mix }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogProductRelatedPostsContainer);

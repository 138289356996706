/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { BLOG_SEARCH } from '../component/BlogSearchBar/BlogSearchBar.config';
import { POSTS_DETAILS } from '../route/PostsDetails/PostsDetails.config';
import { POSTS_LISTING } from '../route/PostsListing/PostsListing.config';

export const stateMap = (originalMember) => ({
    ...originalMember,
    [BLOG_SEARCH]: {
        back: true,
        title: true
    },
    [POSTS_DETAILS]: {
        logo: true
    },
    [POSTS_LISTING]: {
        logo: true
    }
});

export default {
    'Component/Header/Component': {
        'member-property': {
            stateMap
        }
    }
};

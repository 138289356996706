/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */

import BlogRecentPosts from '../component/BlogRecentPosts';
import { BLOG_WIDGET } from '../component/BlogRecentPosts/BlogRecentPosts.config';

const renderMap = (originalMember) => ({
    ...originalMember,
    [BLOG_WIDGET]: {
        component: BlogRecentPosts
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        }
    }
};

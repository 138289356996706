/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import Html from 'SourceComponent/Html';

import { PagebuilderElementType } from '../../type/Elements.type';

/** @namespace Scandiweb/AdobePageBuilder/Component/HtmlCode/Component */
export class HtmlCodeComponent extends PureComponent {
    static propTypes = {
        elements: PagebuilderElementType.isRequired
    };

    renderHtml() {
        const {
            elements: {
                BaseHtmlCode
            }
        } = this.props;

        const html = BaseHtmlCode.childEleBag[0];

        if (!html) {
            return null;
        }

        return (
            <Html content={ html } />
        );
    }

    render() {
        const {
            elements: {
                BaseHtmlCode
            }
        } = this.props;

        return (
            <BaseHtmlCode.Ele>
                { this.renderHtml() }
            </BaseHtmlCode.Ele>
        );
    }
}

export default HtmlCodeComponent;

import { CheckoutQuery as SourceCheckoutQuery } from 'SourceQuery/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Checkout/Query */
export class CheckoutQuery extends SourceCheckoutQuery {
    getPlaceOrderMutation(guestCartId, customerAttributes = null) {
        const mutation = new Field('s_placeOrder')
            .setAlias('placeOrder')
            .addField(this._getOrderField());

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', guestCartId);
        }

        if (customerAttributes) {
            mutation.addArgument('customerAttributes', 'CustomCustomerAttributes!', customerAttributes);
        }

        return mutation;
    }

    _getCommunicationFields() {
        return [
            'id',
            'label',
            'value'
        ];
    }

    getCommunicationsQuery() {
        return new Field('getCommunications')
            .addFieldList(this._getCommunicationFields());
    }
}

export default new CheckoutQuery();

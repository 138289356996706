/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const GDPR_COOKIES_POPUP_ID = 'gdpr_cookies';
export const GDPR_COOKIES_POPUP_STYLE = 'cookie_bar_customisation/popup/';
export const GDPR_COOKIES_CLASSIC_STYLE = 'cookie_bar_customisation/classic_bar/';
export const GDPR_COOKIES_SIDEBAR_STYLE = 'cookie_bar_customisation/sidebar/';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const FIELD_TYPE = {
    email: 'email',
    text: 'text',
    number: 'number',
    radio: 'radio',
    checkbox: 'checkbox',
    textarea: 'textarea',
    password: 'password',
    select: 'select',
    file: 'file',
    multi: 'multi',
    date: 'date',
    time: 'time',
    dateTime: 'datetime-local',
    submit: 'submit',
    button: 'button',
    url: 'url',
    country: 'country',
    state: 'state',
    multiselect: 'multiselect',
    image: 'image',
    star: 'star',
    terms: 'terms',
    hidden: 'hidden',
    send: 'sendcopytome',
    phone: 'phone',
    dealerships: 'dealerships',
    communication: 'communication',
    carModel: 'car_model',
    carManufacturer: 'car_manufacturer'
};

export default FIELD_TYPE;

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy } from 'react';

import { KEY_MILEAGE_SEARCH, KEY_POWER_SEARCH, KEY_YEAR } from './CategoryFilterOverlayContainer.plugin';

export const PriceSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "price-slider" */
    '../component/PriceSlider'
));

export const YearSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "price-slider" */
    '../component/YearSlider'
));

export const PowerSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "price-slider" */
    '../component/PowerSlider'
));

export const MileageSlider = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "price-slider" */
    '../component/MileageSlider'
));

const renderPriceSwatch = (args, callback, instance) => {
    const {
        parameters,
        updateConfigurableVariant,
        configurable_options: {
            price: {
                attribute_code,
                attribute_label
            } = {}
        } = {}
    } = instance.props;

    return (
        <PriceSlider
          parameters={ parameters }
          priceCode={ attribute_code }
          priceLabel={ attribute_label }
          updateConfigurableVariant={ updateConfigurableVariant }
        />
    );
};

const renderConfigurableOption = (args, callback, instance) => {
    const [{ attribute_code }] = args;

    if (attribute_code === KEY_YEAR) {
        const {
            parameters,
            updateConfigurableVariant,
            configurable_options: {
                year: {
                    attribute_code,
                    attribute_label
                } = {}
            } = {}
        } = instance.props;

        return (
            <YearSlider
              parameters={ parameters }
              priceCode={ attribute_code }
              priceLabel={ attribute_label }
              updateConfigurableVariant={ updateConfigurableVariant }
              param_code={ KEY_YEAR }
            />
        );
    }

    if (attribute_code === KEY_POWER_SEARCH) {
        const {
            parameters,
            updateConfigurableVariant,
            configurable_options: {
                power_search: {
                    attribute_code,
                    attribute_label
                } = {}
            } = {}
        } = instance.props;

        return (
            <PowerSlider
              parameters={ parameters }
              priceCode={ attribute_code }
              priceLabel={ attribute_label }
              updateConfigurableVariant={ updateConfigurableVariant }
              param_code={ KEY_POWER_SEARCH }
            />
        );
    }

    if (attribute_code === KEY_MILEAGE_SEARCH) {
        const {
            parameters,
            updateConfigurableVariant,
            configurable_options: {
                mileage_search: {
                    attribute_code,
                    attribute_label
                } = {}
            } = {}
        } = instance.props;

        return (
            <MileageSlider
              parameters={ parameters }
              priceCode={ attribute_code }
              priceLabel={ attribute_label }
              updateConfigurableVariant={ updateConfigurableVariant }
              param_code={ KEY_MILEAGE_SEARCH }
            />
        );
    }

    return callback(...args);
};

export default {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderPriceSwatch,
            renderConfigurableOption
        }
    }
};

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const DEFAULT_BLOG_POSTS_PER_PAGE = 6;

export const _aroundGetInitialState = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    blog_posts_per_page: DEFAULT_BLOG_POSTS_PER_PAGE
});

export default {
    'Store/Config/Reducer/getInitialState': {
        function: _aroundGetInitialState
    }
};

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { ESCAPE_KEY } from 'Component/Popup/Popup.config';
import SourcePopup from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace Scandipwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    onVisible() {
        const { onVisible } = this.props;

        this.freezeScroll();
        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        window.history.pushState(
            {
                popupOpen: true
            },
            '',
            `${location.pathname}${location.search}`
        );

        onVisible();
    }

    handleClickOutside = () => {
        const { activeOverlay } = this.props;

        if (activeOverlay !== 'testDrivePopup') {
            this.hidePopUp();
        }
    };

    handleKeyDown(e) {
        switch (e.keyCode) {
        case ESCAPE_KEY:
            this.hidePopUp();
            break;
        default:
            break;
        }
    }
}

export default PopupComponent;

import {
    CartDispatcher,
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';

export {
    CUSTOMER,
    ONE_MONTH_IN_SECONDS,
    CartDispatcher
};

/** @namespace Scandipwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    /**
     * Override methods to remove login/register functionality
     */

    requestCustomerData() {
        return false;
    }

    logout() {
        return false;
    }

    forgotPassword() {
        return false;
    }

    resetPassword() {
        return false;
    }

    createAccount() {
        return false;
    }

    confirmAccount() {
        return false;
    }

    async signIn() {
        return false;
    }

    handleForceRedirectToLoginPage() {
        return false;
    }

    handleCustomerDataOnInit() {
        return false;
    }
}

export default new MyAccountDispatcher();

/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aivars Arbidans <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React from 'react';

import FieldForm from 'Component/FieldForm';

import MyAccountPrivacySettingsPopup from '../MyAccountPrivacySettingsPopup';

import './MyAccountPrivacySettings.style.scss';

/** @namespace Gdpr/Component/MyAccountPrivacySettings/Component */
export class MyAccountPrivacySettingsComponent extends FieldForm {
    static propTypes = {
        showConfirmPopup: PropTypes.func.isRequired,
        onSaveConsents: PropTypes.func.isRequired,
        updateConsentSelection: PropTypes.func.isRequired
    };

    renderPopup() {
        return <MyAccountPrivacySettingsPopup />;
    }

    renderActions() {
        return (
            <div block="MyAccountPrivacySettings">
                { this.renderPersonalDataSection() }
                { this.renderAnonimiseSection() }
                { this.renderDeleteSection() }
                { this.renderPopup() }
            </div>
        );
    }

    renderPersonalDataSection() {
        const { showConfirmPopupCSV } = this.props;

        return (
            <div block="MyAccountPrivacySettings" elem="Wrapper">
                <div block="MyAccountPrivacySettings" elem="Title">
                    { __('Download Personal Data') }
                </div>
                <div block="MyAccountPrivacySettings" elem="Subtitle">
                    { /* eslint-disable-next-line max-len */ }
                    { __('Here you can download a copy of your personal data which we store for your account in CSV format.') }
                </div>
                <button
                  block="Button"
                  /* eslint-disable-next-line react/jsx-no-bind */
                  onClick={ () => showConfirmPopupCSV(true) }
                >
                    <span>{ __('Download Data') }</span>
                </button>
            </div>
        );
    }

    renderAnonimiseSection() {
        const { showConfirmPopupAnonymise } = this.props;

        return (
            <div block="MyAccountPrivacySettings" elem="Wrapper">
                <div block="MyAccountPrivacySettings" elem="Title">
                    { __('Anonymise personal data') }
                </div>
                <div block="MyAccountPrivacySettings" elem="Subtitle">
                    { /* eslint-disable-next-line max-len */ }
                    { __('Anonymising your personal data means that it will be replaced with non-personal anonymous information and before that you will get your new login to your e-mail address (your password will stay the same). After this process, your e-mail address and all other personal data will be removed from the website.') }
                </div>
                <button
                  block="Button"
                  /* eslint-disable-next-line react/jsx-no-bind */
                  onClick={ () => showConfirmPopupAnonymise(false, true) }
                >
                    <span>{ __('Anonymise data') }</span>
                </button>
            </div>
        );
    }

    renderDeleteSection() {
        const { showConfirmPopupDelete } = this.props;

        return (
            <div block="MyAccountPrivacySettings" elem="Wrapper">
                <div block="MyAccountPrivacySettings" elem="Title">
                    { __('Delete Account') }
                </div>
                <div block="MyAccountPrivacySettings" elem="Subtitle">
                    { /* eslint-disable-next-line max-len */ }
                    { __('Request to remove your account, together with all your personal data, will be processed by our staff. Deleting your account will remove all the purchase history, discounts, orders, invoices and all other information that might be related to your account or your purchases. All your orders and similar information will be lost. You will not be able to restore access to your account after we approve your removal request.') }
                </div>
                <button
                  block="Button"
                  /* eslint-disable-next-line react/jsx-no-bind */
                  onClick={ () => showConfirmPopupDelete(false, false, true) }
                >
                    <span>{ __('Delete Account') }</span>
                </button>
            </div>
        );
    }
}

export default MyAccountPrivacySettingsComponent;

import Notification from 'Component/Notification';
import {
    NotificationList as SourceNotificationList
} from 'SourceComponent/NotificationList/NotificationList.component';

import { EXCLUDE_MSG } from './NotificationList.config';

/** @namespace Scandipwa/Component/NotificationList/Component */
export class NotificationListComponent extends SourceNotificationList {
    /**
     * Overridden to mute homepage notifications
     */
    render() {
        const { onHideNotification, notifications } = this.props;

        if (EXCLUDE_MSG.includes(Object.values(notifications)?.[0]?.msgText)) {
            return null;
        }

        return (
            <div block="NotificationList">
                { Object.keys(notifications).map((id) => (
                    <Notification
                      key={ id }
                      notificationId={ id }
                      notification={ notifications[id] }
                      onHideNotification={ onHideNotification }
                    />
                )) }
            </div>
        );
    }
}

export default NotificationListComponent;

/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */

import ProductLease from 'Component/ProductLease';
import { LEASING_CALCULATOR_WIDGET } from 'Component/ProductLease/ProductLease.config';

const renderMap = (originalMember) => ({
    ...originalMember,
    [LEASING_CALCULATOR_WIDGET]: {
        component: ProductLease
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        }
    }
};

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showPopup } from 'Store/Popup/Popup.action';

import { updateInfoCookieGroup } from '../../store/Cookies';
import { CookiesType } from '../../type/GdprCookie';
import { GDPR_COOKIE_GROUP_POPUP_ID } from '../GdprCookieGroupPopup/GdprCookieGroupPopup.config';
import GdprCookieGroupItem from './GdprCookieGroupItem.component';

/** @namespace GdprCookie/Component/GdprCookieGroupItem/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace GdprCookie/Component/GdprCookieGroupItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(GDPR_COOKIE_GROUP_POPUP_ID, payload)),
    updateInfoCookieGroup: (cookieInfo) => dispatch(updateInfoCookieGroup(cookieInfo))
});

/** @namespace GdprCookie/Component/GdprCookieGroupItem/Container */
export class GdprCookieGroupItemContainer extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        updateInfoCookieGroup: PropTypes.func.isRequired,
        handleCheckedGroups: PropTypes.func.isRequired,
        groupId: PropTypes.string.isRequired,
        isEssential: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        cookies: CookiesType.isRequired
    };

    containerFunctions = {
        showPopup: this.showPopup.bind(this),
        handleToggleCheck: this.handleToggleCheck.bind(this)
    };

    state = {
        isChecked: false
    };

    componentDidMount() {
        const { isEssential, groupId, handleCheckedGroups } = this.props;

        if (isEssential) {
            handleCheckedGroups(groupId, true);
        }
    }

    showPopup() {
        const { showPopup, updateInfoCookieGroup } = this.props;
        const { name, description, cookies } = this.props;

        const cookieInfo = { name, description, cookies };

        updateInfoCookieGroup(cookieInfo);
        showPopup();
    }

    handleToggleCheck() {
        const { handleCheckedGroups, groupId } = this.props;
        const { isChecked } = this.state;

        this.setState(({ isChecked }) => ({ isChecked: !isChecked }));
        handleCheckedGroups(groupId, !isChecked);
    }

    render() {
        const { isChecked } = this.state;
        const {
            isEssential,
            name,
            description
        } = this.props;

        return (
            <GdprCookieGroupItem
              isEssential={ isEssential }
              isChecked={ isChecked }
              name={ name }
              description={ description }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GdprCookieGroupItemContainer);

import { cloneElement } from 'react';

export const renderPayments = (args, callback, instance) => {
    const {
        setPaymentMethodBrandForData
    } = instance.props;

    const originalElement = callback(...args);

    if (!originalElement) {
        return null;
    }

    return cloneElement(
        originalElement,
        {
            setPaymentMethodBrandForData
        }
    );
};

export default {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderPayments
        }
    }
};

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { ChildrenType } from 'Type/Common.type';

import ProductSlider from './ProductSlider.component';
import {
    ANIMATION_DURATION, UPDATE_SLIDER_WIDTH_DURATION
} from './ProductSlider.config';

/** @namespace Scandipwa/Component/ProductSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/ProductSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/ProductSlider/Container */
export class ProductSliderContainer extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    containerFunctions = {
        goLeft: this.goLeft.bind(this),
        goRight: this.goRight.bind(this),
        onSlide: this.onSlide.bind(this),
        onActiveImageChange: this.onActiveImageChange.bind(this)
    };

    sliderRef = createRef();

    state = {
        scrollLeft: 0,
        activeImage: 0
    };

    sliderWidth = 0;

    componentDidMount() {
        const setSliderWidthInterval = setInterval(() => {
            if (this.sliderWidth === 0) {
                const sliderWidth = this.sliderRef.current?.offsetWidth;
                this.sliderWidth = sliderWidth;
            } else {
                clearInterval(setSliderWidthInterval);
            }
        }, UPDATE_SLIDER_WIDTH_DURATION);
    }

    containerProps() {
        const { children, isMobile } = this.props;
        const { activeImage } = this.state;

        return {
            children,
            isMobile,
            sliderRef: this.sliderRef,
            activeImage
        };
    }

    onActiveImageChange(activeImage) {
        this.setState({ activeImage });
    }

    goLeft() {
        const { scrollLeft } = this.state;

        this.sliderRef.current.scrollTo({
            left: scrollLeft + this.sliderWidth,
            top: 0,
            behavior: 'smooth',
            duration: ANIMATION_DURATION
        });
    }

    goRight() {
        const { scrollLeft } = this.state;

        this.sliderRef.current.scrollTo({
            left: scrollLeft - this.sliderWidth,
            top: 0,
            behavior: 'smooth',
            duration: ANIMATION_DURATION
        });
    }

    onSlide() {
        this.setState({
            scrollLeft: this.sliderRef.current.scrollLeft
        });
    }

    render() {
        return (
            <ProductSlider
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSliderContainer);

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { isCrawler } from 'Util/Browser';
import { fetchMutation } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import { fireGeneralEvent } from '../../../../google-tag-manager/src/event/general';
import GdprCookieQuery from '../../query/GdprCookie.query';
import { updateInfoCookieGroup } from '../../store/Cookies/Cookies.action';
import GdprCookiePopup from './GdprCookiePopup.component';
import { GDPR_COOKIES_CLASSIC_STYLE, GDPR_COOKIES_POPUP_STYLE } from './GdprCookiePopup.config';

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay,
    isCookieBarActive: state?.ConfigReducer?.gdpr_cookie_bar,
    code: state.ConfigReducer.code
});

/** @namespace GdprCookie/Component/GdprCookiePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateInfoCookieGroup: (cookieInfo) => dispatch(updateInfoCookieGroup(cookieInfo))
});

/** @namespace GdprCookie/Component/GdprCookiePopup/Container */
export class GdprCookiePopupContainer extends DataContainer {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        showNotification: PropTypes.func.isRequired,
        isCookieBarActive: PropTypes.bool.isRequired
    };

    state = {
        isLoading: true,
        isStatic: true,
        checkedGroups: [],
        isCookieSet: !!this.handleGetCookie('amcookie_allowed'),
        cookieSettings: null,
        barStyle: null
    };

    containerFunctions = {
        handleCheckedGroups: this.handleCheckedGroups.bind(this),
        handleAcceptAllGroups: this.handleAcceptAllGroups.bind(this),
        handleDeclineCookies: this.handleDeclineCookies.bind(this),
        handleAcceptCookies: this.handleAcceptCookies.bind(this),
        handleCustomAction: this.handleCustomAction.bind(this),
        handleClosePopup: this.handleClosePopup.bind(this)
    };

    componentDidMount() {
        const {
            updateInfoCookieGroup
        } = this.props;

        const {
            isCookieSet
        } = this.state;

        if (!isCookieSet) {
            this._getCookieSettings();
        }

        updateInfoCookieGroup({ isCookieSet });
    }

    componentDidUpdate() {
        const { isCookieBarActive } = this.props;
        const {
            isLoading,
            isCookieSet
        } = this.state;

        if (isLoading && isCookieBarActive && !isCookieSet) {
            this._getCookieGroups();
        }
    }

    handleCheckedGroups(groupId, isChecked) {
        const { checkedGroups } = this.state;

        if (isChecked) {
            this.setState({ checkedGroups: [...checkedGroups, groupId] });
        } else {
            this.setState({ checkedGroups: checkedGroups.filter((checkedGroupId) => checkedGroupId !== groupId) });
        }
    }

    async handleAcceptCookies() {
        const { hideActiveOverlay, showNotification, updateInfoCookieGroup } = this.props;
        const { checkedGroups } = this.state;

        const request = await fetchMutation(GdprCookieQuery.setSelectedCookies(checkedGroups));

        const { setSelectedCookies: { message, result } } = request;

        if (result) {
            updateInfoCookieGroup({ isCookieSet: true });
            fireGeneralEvent();
            showNotification('success', __(message));
            this.setState({ isStatic: false });
            hideActiveOverlay();
        }
    }

    async handleAcceptAllGroups() {
        const { hideActiveOverlay, showNotification, updateInfoCookieGroup } = this.props;

        const request = await fetchMutation(GdprCookieQuery.setAllCookies());

        const { setAllCookies: { message, result } } = request;

        if (result) {
            updateInfoCookieGroup({ isCookieSet: true });
            fireGeneralEvent();
            showNotification('success', __(message));
            this.setState({ isStatic: false });
            hideActiveOverlay();
        }
    }

    async handleDeclineCookies() {
        const { hideActiveOverlay, updateInfoCookieGroup } = this.props;

        await fetchMutation(GdprCookieQuery.setSelectedCookies([]));

        updateInfoCookieGroup({ isCookieSet: true });
        fireGeneralEvent();
        this.setState({ isStatic: false });
        hideActiveOverlay();
    }

    handleCustomAction() {
        this.setState({ barStyle: GDPR_COOKIES_POPUP_STYLE });
    }

    handleClosePopup() {
        this.setState({ barStyle: GDPR_COOKIES_CLASSIC_STYLE });
    }

    handleGetCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }

        return '';
    }

    _getCookieGroups() {
        this.fetchData(
            [GdprCookieQuery.getCookieGroups()],
            ({ cookieGroups }) => {
                this.setState({ isLoading: false });
                this.setState({ cookieGroups });
            }
        );
    }

    _getCookieSettings() {
        this.fetchData(
            [GdprCookieQuery.getCookieSettings()],
            ({ gdprCookieSettings }) => {
                const { gdpr_cookie_bar_style } = gdprCookieSettings;
                this.setState({
                    cookieSettings: gdprCookieSettings,
                    barStyle: gdpr_cookie_bar_style
                });
            }
        );
    }

    render() {
        if (isCrawler()) {
            return null;
        }

        const {
            cookieGroups, isCookieSet, isLoading, isStatic, cookieSettings, barStyle
        } = this.state;
        const { isCookieBarActive, code } = this.props;
        return (
            <GdprCookiePopup
              cookieGroups={ cookieGroups }
              isCookieSet={ isCookieSet }
              isLoading={ isLoading }
              isStatic={ isStatic }
              barStyle={ barStyle }
              code={ code }
              isCookieBarActive={ isCookieBarActive }
              cookieSettings={ cookieSettings }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GdprCookiePopupContainer);

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Html from "Component/Html";
import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';

import { getPostUrl } from '../../util/Posts';
import { MAX_POST_TAGS, MAX_RECENT_POSTS } from './BlogRecentPosts.config';

import './BlogRecentPosts.style';

/** @namespace Blog/Component/BlogRecentPosts/Component */
export class BlogRecentPostsComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        posts: PropTypes.arrayOf(Object).isRequired,
        isLoaded: PropTypes.bool.isRequired
    };

    renderTags(tags) {
        if (!tags) {
            return null;
        }

        return tags.slice(0, MAX_POST_TAGS).map((tag) => {
            const { title, identifier } = tag;

            return (
                <Link
                  to={ `/blog/${ identifier}` }
                  block="BlogRecentPosts"
                  elem="Tag"
                >
                    { title }
                </Link>
            );
        });
    }

    renderContents() {
        const { posts, isLoaded } = this.props;
        const post = {};

        if (!isLoaded) {
            return Array(MAX_RECENT_POSTS).fill().map((_, i) => this.renderCard(post, i));
        }

        return posts.map(this.renderCard.bind(this));
    }

    renderContent(content) {
        if (!content) {
            return '';
        }

        return (
            <div block="BlogRecentPosts" elem="PostDescription">
                <Html content={ content } />
            </div>
        );
    }

    renderCard(post, key) {
        const {
            title, first_image, short_content
        } = post || {};
        const postUrl = getPostUrl(post);

        return (
            <Link
              to={ postUrl }
              title={ __('View %s', title) }
              mix={ {
                  block: 'BlogRecentPosts',
                  elem: 'PostCard'
              } }
              key={ key }
            >
                <div
                  block="BlogRecentPosts"
                  elem="ImageWrapper"
                >
                    <Image
                      src={ first_image }
                      ratio="16x9"
                      isPlaceholder={ !first_image }
                    />
                </div>
                <div
                  block="BlogRecentPosts"
                  elem="ContentWrapper"
                >

                    <h2 block="BlogRecentPosts" elem="PostTitle">
                        <TextPlaceholder
                          mix={ { block: 'BlogRecentPosts', elem: 'PostTitlePlaceholder' } }
                          content={ title }
                          length="short"
                        />
                    </h2>

                    { this.renderContent(short_content) }

                    <div
                      to={ postUrl }
                      className="Button Button_isHollow"
                      block="BlogRecentPosts"
                      elem="Button"
                    >
                        { __('Read more') }
                    </div>
                </div>
            </Link>
        );
    }

    render() {
        const { title } = this.props;
        return (
            <div block="BlogRecentPosts">
                <h1
                  block="BlogRecentPosts"
                  elem="Title"
                  mix={ {
                      block: 'HomePage',
                      elem: 'Heading'
                  } }
                >
                    { title }
                </h1>
                <div block="BlogRecentPosts" elem="Wrapper">
                    { this.renderContents() }
                </div>
                <Link
                  to="/blog"
                  className="Button"
                  block="BlogRecentPosts"
                  elem="Button"
                >
                    { __('Read all news') }
                </Link>
            </div>
        );
    }
}

export default BlogRecentPostsComponent;

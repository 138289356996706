import { UPDATE_FLEXIBLE_FORMS } from './FlexibleForms.action';

/** @namespace Scandiweb/Flexibleforms/Store/FlexibleForms/Reducer/getInitialState */
export const getInitialState = () => ({
    flexibleForms: {
        flexibleFormsConfig: {
            enable: false,
            enable_footer_link: false,
            frontend_flexibleforms_title: false
        },
        getAllFormsData: [],
        getForm: {
            fields_sets: []
        }
    }
});

/** @namespace Scandiweb/Flexibleforms/Store/FlexibleForms/Reducer/FlexibleFormsReducer */
export const FlexibleFormsReducer = (state = getInitialState(), action) => {
    const { type, flexibleForms } = action;

    switch (type) {
    case UPDATE_FLEXIBLE_FORMS:
        return {
            ...state,
            ...flexibleForms
        };

    default:
        return state;
    }
};

export default FlexibleFormsReducer;

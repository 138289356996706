/**
 * Adobe page builder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const HTML_CODE_CONTENT_TYPE = 'html';

export const HTML_CODE_SKELETON = [{
    name: 'BaseHtmlCode', type: 'div'
}];

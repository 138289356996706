/* eslint-disable fp/no-let */
/* eslint-disable react/no-danger */
/* eslint-disable eqeqeq */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import Form from 'Component/Form/Form.container';
import Loader from 'Component/Loader';
import { trimEndSlash } from 'Util/Url';

import {
    BLOCK_NAME,
    CAR_URL_TITLES,
    COMPARABLE_FIELD_CLASS,
    FLEXIBLEFORM_ENABLED,
    STAR_NUMBERS,
    XML_TO_FORMS
} from './FlexibleFormsWidget.config';

import './FlexibleFormsWidget.style';

/** @namespace Scandiweb/Flexibleforms/Component/FlexibleFormsWidget/Component */
export class FlexibleFormsWidgetComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isSubmitLoading: PropTypes.bool.isRequired,
        containerState: PropTypes.object.isRequired,
        handleSelectOnChange: PropTypes.func.isRequired,
        handleFieldOnChange: PropTypes.func.isRequired,
        handleFieldPrefill: PropTypes.func.isRequired,
        handleCheckboxOnChange: PropTypes.func.isRequired,
        handleFileTypeOnChange: PropTypes.func.isRequired,
        handleRadioOnChange: PropTypes.func.isRequired,
        handleStarOnChange: PropTypes.func.isRequired,
        handleRecaptchaonChange: PropTypes.func.isRequired,
        handleMultiSelectOnChange: PropTypes.func.isRequired,
        handleOptionCheckboxOnChange: PropTypes.func.isRequired,
        postFormData: PropTypes.func.isRequired,
        flexibleForms: PropTypes.objectOf({
            getForm: PropTypes.object,
            flexibleFormsConfig: PropTypes.object
        }).isRequired,
        showPopup: PropTypes.func.isRequired,
        base_url: PropTypes.string.isRequired,
        product_url: PropTypes.string,
        dealer: PropTypes.object
    };

    static defaultProps = {
        product_url: '',
        dealer: {}
    };

    recaptchaRef = createRef();

    /**
     * Create mix object to pass to field attributes from custom class parameter
     */
    getMix(fieldClass, fieldType) {
        const field_class = fieldClass.replace(/[^a-zA-Z-]/g, '');
        const field_type = fieldType.replace(/[^a-zA-Z]/g, '');
        if (field_class) {
            const CLASS_REGEX = /(?=\S*[-])([a-zA-Z-]+)/;
            if (CLASS_REGEX.test(field_class)) {
                const bemElems = field_class.split('-');
                return {
                    block: bemElems[0],
                    elem: bemElems[1]
                };
            }

            return {
                block: BLOCK_NAME,
                elem: field_class
            };
        }

        return {
            block: BLOCK_NAME,
            elem: field_type
        };
    }

    /**
     * Return true if the passed checkbox field has options
     */
    isCheckBoxGroup(field) {
        if (field.field_type === FIELD_TYPE.checkbox) {
            if (field.field_options && field.field_options.length > 0) {
                return true;
            }
        }

        return false;
    }

    /**
     * Get the checkbox checked value from the checkboxes state object
     */
    getChecked(id) {
        const { checkboxes } = this.props.containerState;
        return checkboxes.hasOwnProperty(id) ? checkboxes[id] : false;
    }

    /**
     * START get attributes for each field type
     */
    getSelectAttributes(field) {
        const {
            handleSelectOnChange, handleMultiSelectOnChange, handleFieldPrefill, dealer
        } = this.props;
        const {
            field_id,
            field_options,
            field_required,
            field_title,
            field_type,
            field_initial_type
        } = field;

        const required = field_required == FLEXIBLEFORM_ENABLED;
        const handler = field_type === FIELD_TYPE.multiselect ? handleMultiSelectOnChange : handleSelectOnChange;
        const defaultValue = (window.location.pathname.includes('test-drive') && Object.keys(dealer).length !== 0)
            ? XML_TO_FORMS?.[Object.values(dealer)?.[0]?.label]
            : '';

        let modifiedOptions = [];
        let isModifiedSelectOptions = false;
        switch (field_initial_type) {
        case FIELD_TYPE.carModel:
            modifiedOptions = this.prepareCarModelOptions(field);
            isModifiedSelectOptions = true;
            break;
        case FIELD_TYPE.dealerships:
            modifiedOptions = this.prepareDealershipOptions(field);
            isModifiedSelectOptions = true;
            break;
        default:
            break;
        }

        return {
            type: field_type,
            options: isModifiedSelectOptions ? modifiedOptions : field_options,
            label: field_title,
            events: {
                onChange: handler
            },
            attr: {
                id: field_id,
                name: field_title,
                defaultValue
            },
            validateOn: ['onChange'],
            validationRule: {
                isRequired: isModifiedSelectOptions ? true : required
            },
            prefill: ((defaultValue !== '') && (defaultValue !== undefined)),
            handleFieldPrefill
        };
    }

    /**
     * Prepare Dealership options
     * depends on selected Car Manufacturer
     *
     * @param field
     * @returns {*|*[]}
     */
    prepareDealershipOptions(field) {
        const { product_url } = this.props;
        const {
            field_options,
            field_initial_type
        } = field;

        if (field_initial_type !== FIELD_TYPE.dealerships) {
            return [];
        }

        if (product_url) {
            return field_options;
        }

        const {
            containerState: {
                form: {
                    options = {}
                } = {}
            } = {},
            flexibleForms: {
                getForm: {
                    field_sets = {}
                } = {}
            } = {}
        } = this.props;

        const { 0: { fields } = {} } = field_sets;

        if (fields) {
            const carManufacturerField = fields.filter(
                ({ field_initial_type }) => field_initial_type === FIELD_TYPE.carManufacturer
            );

            if (carManufacturerField.length > 0) {
                const {
                    field_id: carManufacturerFieldId,
                    field_options: carManufacturerFieldOptions
                } = carManufacturerField[0];

                const carManufacturerSelectedValue = options[carManufacturerFieldId];
                const selectedCarManufacturerOption = carManufacturerFieldOptions.filter(
                    ({ value }) => value === carManufacturerSelectedValue
                );

                const { id } = selectedCarManufacturerOption[0] || {};
                const dealershipField = fields.filter(
                    ({ field_initial_type }) => field_initial_type === FIELD_TYPE.dealerships
                );

                if (dealershipField.length > 0) {
                    const {
                        field_options: dealershipFieldOptions
                    } = dealershipField[0];

                    if (dealershipFieldOptions) {
                        return dealershipFieldOptions.filter(
                            ({ allowed_car_manufacturer }) => allowed_car_manufacturer?.includes(id)
                        );
                    }
                }
            }
        }

        return field_options;
    }

    /**
     * Prepare Car Model options
     * depends on selected Car Manufacturer
     *
     * @param field
     * @returns {*|*[]}
     */
    prepareCarModelOptions(field) {
        const {
            field_options,
            field_initial_type
        } = field;

        if (field_initial_type !== FIELD_TYPE.carModel) {
            return [];
        }

        const {
            containerState: {
                form: {
                    options = {}
                } = {}
            } = {},
            flexibleForms: {
                getForm: {
                    field_sets = {}
                } = {}
            } = {}
        } = this.props;

        const { 0: { fields } = {} } = field_sets;

        if (fields) {
            const carManufacturerField = fields.filter(
                ({ field_initial_type }) => field_initial_type === FIELD_TYPE.carManufacturer
            );

            if (carManufacturerField.length > 0) {
                const {
                    field_id: carManufacturerFieldId,
                    field_options: carManufacturerFieldOptions
                } = carManufacturerField[0];

                const carManufacturerSelectedValue = options[carManufacturerFieldId];
                const selectedManufacturerOption = carManufacturerFieldOptions.filter(
                    ({ value }) => value === carManufacturerSelectedValue
                );
                const { id: selectedManufacturerOptionId } = selectedManufacturerOption[0] || {};

                return field_options.filter((option) => option.parent_id == selectedManufacturerOptionId);
            }
        }

        return [];
    }

    getInputAttributes(field) {
        const {
            handleFieldOnChange, handleFieldPrefill, base_url, product_url
        } = this.props;
        const {
            field_id,
            field_options,
            field_required,
            field_title,
            field_type,
            field_value
        } = field;

        const required = field_required == FLEXIBLEFORM_ENABLED;

        const validations = [FIELD_TYPE.number, FIELD_TYPE.email, FIELD_TYPE.url, FIELD_TYPE.date, FIELD_TYPE.phone];

        // custom error message for comment field only if it's required
        const onRequirementFail = (field_type === FIELD_TYPE.textarea)
            ? __('Please indicate which department you would like to contact') : '';

        const validationRule = {
            isRequired: required,
            customErrorMessages: {
                onRequirementFail
            }
        };
        const tp = { type: field_type };

        if (validations.includes(field_type)) {
            if (field_type === FIELD_TYPE.email && field.field_class === COMPARABLE_FIELD_CLASS) {
                validationRule.match = () => {
                    const fieldSet = this.props.flexibleForms.getForm.field_sets[0].fields;
                    const verifyEmailInputField = fieldSet.filter(
                        (e) => e.field_type === field_type && e.field_id !== field_id
                    )[0];
                    const verifyEmailInputFieldValue = this.props.containerState.form.options[
                        verifyEmailInputField.field_id
                    ];
                    const currentFieldValue = this.props.containerState.form.options[field_id];
                    if (verifyEmailInputFieldValue === currentFieldValue) {
                        return true;
                    }

                    return __(field.field_custom_validation);
                };
            } else if (field_type === FIELD_TYPE.phone) {
                validationRule.inputType = 'phone';
                tp.type = FIELD_TYPE.text;
            } else if (field_type !== FIELD_TYPE.number) {
                validationRule.inputType = field_type;
            } else {
                validationRule.inputType = 'numeric';
                tp.type = FIELD_TYPE.text;
            }
        }

        const defaultValue = (CAR_URL_TITLES.includes(field_title) && (product_url !== ''))
            ? `${ trimEndSlash(base_url) }${ product_url }`
            : field_value;

        return {
            type: tp.type,
            options: field_options,
            label: field_title,
            events: {
                onChange: handleFieldOnChange
            },
            validationRule,
            attr: {
                id: field_id,
                name: field_title,
                defaultValue
            },
            validateOn: ['onChange'],
            prefill: defaultValue !== field_value,
            handleFieldPrefill
        };
    }

    getCheckboxAttributes(field) {
        const {
            handleCheckboxOnChange
        } = this.props;
        const {
            field_id,
            field_options,
            field_required,
            field_title,
            field_type
        } = field;

        const required = field_required == FLEXIBLEFORM_ENABLED;
        const DEFAULT_TERMS_URL = '/eng/terms-and-conditions';

        return {
            type: field_type,
            options: field_options,
            label: field_title,
            events: {
                onChange: handleCheckboxOnChange
            },
            attr: {
                url: field_options[0] ? field_options[0].value : DEFAULT_TERMS_URL,
                id: field_id,
                name: field_title,
                value: field_title,
                checked: this.getChecked(field_id)
            },
            validationRule: {
                isRequired: field_type === FIELD_TYPE.terms ? true : required
            },
            validateOn: ['onChange']

        };
    }

    getFileAttributes(field) {
        const { handleFileTypeOnChange } = this.props;
        const {
            field_id,
            field_options,
            field_required,
            field_title,
            field_type
        } = field;

        const required = field_required == FLEXIBLEFORM_ENABLED;

        const imgExtensions = 'jpg, jpeg, png';
        const fileExtensions = 'jpg, jpeg, png, pdf, docx, odt, zip, tar, gz';

        return {
            type: field_type,
            options: field_options,
            label: field_title,
            events: {
                onChange: handleFileTypeOnChange
            },
            attr: {
                id: field_id,
                name: field_title
            },
            validationRule: {
                isRequired: required,
                fileExtension: {
                    accept: field_type === FIELD_TYPE.image ? imgExtensions : fileExtensions
                }
            },
            validateOn: ['onChange']

        };
    }

    /**
     * END get attributes for each field type
     */
    /**
     * Mapping of attributes to field types
     */
    getFieldMap(field) {
        if ([
            FIELD_TYPE.dealerships,
            FIELD_TYPE.communication,
            FIELD_TYPE.carModel,
            FIELD_TYPE.carManufacturer
        ].includes(field.field_type)) {
            // eslint-disable-next-line no-param-reassign
            field.field_initial_type = field.field_type;
            // eslint-disable-next-line no-param-reassign
            field.field_type = FIELD_TYPE.select;
        }

        const fieldMap = {
            [FIELD_TYPE.select]: this.getSelectAttributes(field),
            [FIELD_TYPE.country]: this.getSelectAttributes(field),
            // Checkboxes & Radio
            [FIELD_TYPE.checkbox]: this.getCheckboxAttributes(field),

            // Default input
            [FIELD_TYPE.email]: this.getInputAttributes(field),
            [FIELD_TYPE.text]: this.getInputAttributes(field),
            [FIELD_TYPE.time]: this.getInputAttributes(field),
            [FIELD_TYPE.dateTime]: this.getInputAttributes(field),
            [FIELD_TYPE.date]: this.getInputAttributes(field),

            // Custom fields
            [FIELD_TYPE.file]: this.getFileAttributes(field),
            [FIELD_TYPE.textarea]: this.getInputAttributes(field),
            [FIELD_TYPE.number]: this.getInputAttributes(field),

            // Flexible form fields
            [FIELD_TYPE.image]: this.getFileAttributes(field),
            [FIELD_TYPE.url]: this.getInputAttributes(field),
            [FIELD_TYPE.state]: this.getInputAttributes(field),
            [FIELD_TYPE.terms]: this.getCheckboxAttributes(field),
            [FIELD_TYPE.multiselect]: this.getSelectAttributes(field),
            [FIELD_TYPE.send]: this.getCheckboxAttributes(field),
            [FIELD_TYPE.phone]: this.getInputAttributes(field)
        };

        return {
            ...fieldMap[field.field_type]
        };
    }

    /**
     * Render individual field
     */
    renderField(field) {
        const { product_url } = this.props;
        const {
            field_id,
            field_status,
            field_class,
            field_type,
            field_initial_type,
            field_required
        } = field;

        const disabledFields = [FIELD_TYPE.carManufacturer, FIELD_TYPE.carModel, FIELD_TYPE.dealerships];

        if (product_url
            && window.location.pathname.includes('test-drive')
            && disabledFields.includes(field_initial_type)
        ) {
            return null;
        }

        if (!product_url && field_type === FIELD_TYPE.url && window.location.pathname.includes('test-drive')) {
            return null;
        }

        // sometimes field_class is empty despite the field being required
        const new_field_class = (field_required === 1) ? 'required' : field_class;

        const mix = this.getMix(new_field_class, field_type);

        if (field_status) {
            return (
                <Field
                  mix={ mix }
                  key={ field_id }
                  { ...this.getFieldMap(field) }
                />
            );
        }

        return null;
    }

    /**
     * Render radio field used in field groups
     */
    renderFieldGroupRadio(option, field_id, handler) {
        return (
            <Field
              mix={ { block: BLOCK_NAME, elem: 'RadioButton' } }
              key={ option.id }
              type={ FIELD_TYPE.radio }
              label={ option.label }
              attr={ {
                  id: option.id,
                  value: option.value,
                  name: field_id
              } }
              events={ {
                  onChange: handler.onChange
              } }
            />
        );
    }

    /**
     * Render checkbox field used in field groups
     */
    renderFieldGroupCheckbox(option, field_id) {
        const { handleOptionCheckboxOnChange } = this.props;
        const attr = {
            id: `o${ option.id }`,
            value: option.value,
            name: field_id,
            checked: this.getChecked(`o${ option.id }`)
        };

        return (
            <Field
              mix={ { block: BLOCK_NAME, elem: 'CheckBox' } }
              key={ option.id }
              type={ FIELD_TYPE.checkbox }
              label={ option.label }
              attr={ { ...attr } }
              events={ {
                  onChange: (e) => handleOptionCheckboxOnChange(e, attr)
              } }
            />
        );
    }

    /**
     * Render field group
     */
    renderFieldGroup(field) {
        const {
            field_id,
            field_options,
            field_required,
            field_status,
            field_type
        } = field;

        const handler = {};

        const { handleRadioOnChange, handleStarOnChange } = this.props;
        handler.onChange = handleRadioOnChange;

        if (field_type === FIELD_TYPE.star) {
            for (let i = 1; i <= STAR_NUMBERS; i++) {
                field_options[i - 1] = {
                    id: `${ field_id }-${ i }`,
                    label: `${ i } Star`,
                    value: i
                };
            }
            handler.onChange = handleStarOnChange;
        }

        const required = field_required == 1;

        if (field_status) {
            return (
                <FieldGroup
                  key={ field_id }
                  mix={ { block: BLOCK_NAME, elem: 'FieldGroup' } }
                  validationRule={ {
                      isRequired: required
                  } }
                  validateOn={ ['onChange'] }
                >
                    { field_options.map((option) => (
                        field_type === FIELD_TYPE.checkbox
                            ? this.renderFieldGroupCheckbox(option, field_id)
                            : this.renderFieldGroupRadio(option, field_id, handler.onChange))) }
                </FieldGroup>
            );
        }

        return null;
    }

    /**
     * Render the field set
     */
    renderFieldSet(field_set) {
        const {
            fieldset_id,
            fieldset_description,
            fieldset_title,
            fields,
            fieldset_status
        } = field_set;

        if (fieldset_status === FLEXIBLEFORM_ENABLED && fields) {
            return (
                <div key={ fieldset_id }>
                    <hr />
                    <h3>{ fieldset_title }</h3>
                    <p>{ fieldset_description }</p>
                    <hr />
                    <div>
                        { fields.map((field) => {
                            if (field.field_type === FIELD_TYPE.radio
                                || field.field_type === FIELD_TYPE.star
                                || this.isCheckBoxGroup(field)) {
                                return this.renderFieldGroup(field);
                            }

                            return this.renderField(field);
                        }) }
                    </div>
                </div>
            );
        }

        return null;
    }

    /**
     * Render recaptcha field if enabled
     */
    renderRecaptcha() {
        const {
            flexibleForms: {
                flexibleFormsConfig: {
                    recaptcha_enabled,
                    site_key
                },
                getForm: {
                    enable_captcha
                }
            },
            handleRecaptchaonChange

        } = this.props;

        if (recaptcha_enabled) {
            if (enable_captcha == FLEXIBLEFORM_ENABLED) {
                if (site_key) {
                    return (
                        <ReCAPTCHA
                          ref={ this.recaptchaRef }
                          sitekey={ site_key }
                          onChange={ handleRecaptchaonChange }
                        />
                    );
                }
            }
        }

        return null;
    }

    /**
     * Form submit handler
     */
    onFormSubmit() {
        const { postFormData } = this.props;
        postFormData();
        this.recaptchaRef?.current?.reset();
    }

    /**
     * Render form
     */
    renderFlexibleForms() {
        const {
            flexibleForms: {
                flexibleFormsConfig: {
                    enable
                },
                form_id,
                getForm: {
                    form_title,
                    form_top_description,
                    form_bottom_description,
                    form_button_text,
                    field_sets,
                    form_status
                }
            },
            isSubmitLoading
        } = this.props;

        const onSubmitHandler = this.onFormSubmit.bind(this);

        if (enable && field_sets && form_status === FLEXIBLEFORM_ENABLED) {
            return (
                <Form
                    // eslint-disable-next-line react/jsx-no-bind
                  onSubmit={ onSubmitHandler }
                  attr={ {
                      id: form_id
                  } }
                >
                    <h2 dangerouslySetInnerHTML={ { __html: form_title } } />

                    <p dangerouslySetInnerHTML={ { __html: form_top_description } } />

                    { field_sets.map((fieldset) => this.renderFieldSet(fieldset)) }

                    { this.renderRecaptcha() }

                    <Loader isLoading={ isSubmitLoading } />
                    <button
                      type="submit"
                      block="Button"
                      mix={ { block: BLOCK_NAME, elem: 'Submit' } }
                      disabled={ isSubmitLoading }
                    >
                        { form_button_text || __('Submit') }
                    </button>
                    <p dangerouslySetInnerHTML={ { __html: form_bottom_description } } />

                </Form>
            );
        }

        return null;
    }

    render() {
        const { isLoading } = this.props;
        return (
            <div block="FlexibleFormsWidget">
                { !isLoading && this.renderFlexibleForms() }
            </div>
        );
    }
}

export default FlexibleFormsWidgetComponent;

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 */

// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { DeviceType } from 'Type/Device.type';
import { appendWithStoreCode } from 'Util/Url';

import './StickyFooter.style';

/** @namespace Scandipwa/Component/StickyFooter/Component */
// eslint-disable-next-line react/no-redundant-should-component-update
export class StickyFooterComponent extends PureComponent {
    static propTypes = {
        isVisibleOnMobile: PropTypes.bool,
        device: DeviceType.isRequired,
        code: PropTypes.string.isRequired
    };

    static defaultProps = {
        isVisibleOnMobile: false
    };

    render() {
        const {
            isVisibleOnMobile,
            device,
            code
        } = this.props;

        const storeURL = (code || '').includes('_') ? code.replace(/_/g, '-') : code;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        return (
            <section
              block="StickyFooter"
              elem="Wrapper"
              // eslint-disable-next-line react/jsx-no-bind
            >
                <ul
                  block="StickyFooter"
                  elem="List"
                >
                    <li
                      block="StickyFooter"
                      elem="Item"
                    >
                        <a
                          block="StickyFooter"
                          elem="Button"
                          href={ appendWithStoreCode(`/test-drive-${storeURL}`) }
                        >
                            <span
                              block="StickyFooter"
                            >
                                { __('Apply for a test drive') }
                            </span>
                            <div
                              block="StickyFooter"
                              elem="CarIcon"
                              alt="Test Drive Icon"
                            />
                        </a>
                    </li>
                    <li
                      block="StickyFooter"
                      elem="Item"
                    >
                        <a
                          block="StickyFooter"
                          elem="Button"
                          href={ appendWithStoreCode(`/service-${storeURL}`) }
                        >
                            <span
                              block="StickyFooter"
                            >
                                { __('Apply for service') }
                            </span>
                            <div
                              block="StickyFooter"
                              elem="RepairIcon"
                              alt="Service Icon"
                            />
                        </a>
                    </li>
                    <li
                      block="StickyFooter"
                      elem="Item"
                    >
                        <a
                          block="StickyFooter"
                          elem="Button"
                          href={ appendWithStoreCode(`/leasing-form-${storeURL}`) }
                        >
                            <span
                              block="StickyFooter"
                            >
                                { __('Apply for leasing') }
                            </span>
                            <div
                              block="StickyFooter"
                              elem="FormIcon"
                              alt="Offer Icon"
                            />
                        </a>
                    </li>
                    <li
                      block="StickyFooter"
                      elem="Item"
                    >
                        <a
                          block="StickyFooter"
                          elem="Button"
                          href={ appendWithStoreCode('/stores') }
                        >
                            <span
                              block="StickyFooter"
                            >
                                { __('Contacts') }
                            </span>
                            <div
                              block="StickyFooter"
                              elem="ContactIcon"
                              alt="Contacts Icon"
                            />
                        </a>
                    </li>
                </ul>
            </section>
        );
    }
}

export default StickyFooterComponent;

/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aivars Arbidans <info@scandiweb.com>
 */

import { Field } from 'Util/Query';

/** @namespace Gdpr/Query/PrivacySettings/Query */
export class PrivacySettingsQuery {
    getCustomerPrivacyData() {
        return new Field('getCustomerData')
            .addFieldList(this._getCustomerDataResponse());
    }

    deleteCustomer() {
        return new Field('deleteCustomer')
            .addFieldList(this._getCustomerResponse());
    }

    anonymiseCustomerData() {
        return new Field('anonymiseCustomerData')
            .addFieldList(this._getCustomerResponse());
    }

    _getCustomerResponse() {
        return [
            'result',
            'message'
        ];
    }

    _getCustomerDataResponse() {
        return [
            this._getCustomerDataResultFields(),
            'message'
        ];
    }

    _getCustomerDataResultFields() {
        return new Field('result')
            .addFieldList([
                'key',
                'value'
            ]);
    }
}

export default new PrivacySettingsQuery();

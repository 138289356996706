/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 */

export const EXCLUDE_MSG = [
    "The CMS page with the \"home-lv\" ID doesn't exist.",
    "The CMS page with the \"home-lv-ru\" ID doesn't exist.",
    "The CMS page with the \"home-lv-en\" ID doesn't exist.",
    "The CMS page with the \"home-lt\" ID doesn't exist.",
    "The CMS page with the \"home-lt-ru\" ID doesn't exist.",
    "The CMS page with the \"home-lt-en\" ID doesn't exist.",
    "The CMS page with the \"home-ee\" ID doesn't exist.",
    "The CMS page with the \"home-ee-ru\" ID doesn't exist.",
    "The CMS page with the \"home-ee-en\" ID doesn't exist."
];

/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-curly-spacing */
import RenderWhenVisible from 'Component/RenderWhenVisible';
import { WidgetFactory as SourceWidgetFactory } from 'SourceComponent/WidgetFactory/WidgetFactory.component';

/** @namespace Scandipwa/Component/WidgetFactory/Component */
export class WidgetFactoryComponent extends SourceWidgetFactory {
    renderContent() {
        const {
            type,
            sliderId = null,
            displayType,
            productsCount,
            showPager,
            storeId,
            title,
            conditionsEncoded,
            form_id,
            number_of_posts,
            link,
            link_text
        } = this.props;
        const {
            component: Widget,
            fallback
        } = this.renderMap[type] || {};

        if (Widget !== undefined) {
            return (
                <RenderWhenVisible fallback={fallback}>
                    <Widget
                        sliderId={sliderId}
                        displayType={displayType}
                        postsCount={number_of_posts}
                        productsCount={productsCount}
                        showPager={showPager}
                        storeId={storeId}
                        form_id={form_id}
                        title={title}
                        conditionsEncoded={conditionsEncoded}
                        link={link}
                        link_text={link_text}
                    />
                </RenderWhenVisible>
            );
        }

        return null;
    }
}

export default WidgetFactoryComponent;

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { Overlay as SourceOverlay } from 'SourceComponent/Overlay/Overlay.component';
import { toggleScroll } from 'Util/Browser';

/** @namespace Scandipwa/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    /**
     * Overridden to disable scroll on tablets
     */
    onVisible() {
        const { onVisible } = this.props;

        this.freezeScroll();
        this.overlayRef.current.focus();
        onVisible();
    }

    /**
     * Overridden to enable scroll on tablets
     */
    onHide() {
        const { onHide } = this.props;

        this.unfreezeScroll();
        onHide();
    }

    /**
     * Overridden to remove body inline style for margin top
     */
    freezeScroll() {
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
    }
}

export default OverlayComponent;

import { connect } from 'react-redux';

import {
    DEFAULT_STATE,
    mapDispatchToProps,
    mapStateToProps,
    NavigationAbstractContainer as SourceNavigationAbstractContainer
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

export {
    DEFAULT_STATE,
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/NavigationAbstract/Container */
export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    /**
     * Overridden to remove handlePageScroll()
     */
    componentDidMount() {
        const { setNavigationState } = this.props;
        setNavigationState(this.getNavigationState());
        history.listen((history) => {
            this.setState(this.onRouteChanged(history));
        });
    }

    /**
     * Overridden leave overlays visible for tablets
     */
    onRouteChanged(history) {
        const { device: { isTablet, isMobile } } = this.props;

        // check if token is expired and logout
        isSignedIn();

        if (isTablet || isMobile) {
            return this.handleMobileUrlChange(history);
        }

        return this.handleDesktopRouteChange(history);
    }

    /**
     * Overridden to hide active overlay and enable scroll
     */
    handleMobileUrlChange(history) {
        const { hideActiveOverlay } = this.props;
        const { prevPathname } = this.state;
        const { pathname } = history;

        if (prevPathname === pathname) {
            return {};
        }

        hideActiveOverlay();
        this.handlePageScroll();

        return this.handleMobileRouteChange(history);
    }

    /**
     * Overridden to hide active overlay and enable scroll
     */
    handleDesktopRouteChange() {
        const {
            hideActiveOverlay,
            setNavigationState
        } = this.props;

        setNavigationState(this.routeMap['/']);
        hideActiveOverlay();
        this.handlePageScroll();

        return {};
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAbstractContainer);

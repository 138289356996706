import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LeasingQuery from 'Query/Leasing.query';
import { ProductType } from 'SourceType/ProductList.type';
import { getPrice } from 'Util/Product/Extract';
import DataContainer from 'Util/Request/DataContainer';

import ProductLease from './ProductLease.component';
import {
    DECIMAL_DIVISOR, DEFAULT_INTERVAL, LEASE_PERIODS, MONTHS_IN_YEAR
} from './ProductLease.config';

/** @namespace Scandipwa/Component/ProductLease/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Scandipwa/Component/ProductLease/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/ProductLease/Container */
export class ProductLeaseContainer extends DataContainer {
    static propTypes = {
        product: ProductType.isRequired,
        code: PropTypes.string.isRequired,
        storeData: PropTypes.shape({
            store_name: PropTypes.string,
            address: PropTypes.string,
            phone_number: PropTypes.string,
            store_email: PropTypes.string
        }).isRequired,
        handleCalculate: PropTypes.func.isRequired,
        handlePeriodChange: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleCalculate: this.handleCalculate.bind(this),
        handlePeriodChange: this.handlePeriodChange.bind(this)
    };

    state = {
        selectedPeriod: 84,
        monthlyPayment: 0,
        leasingInterestRate: 3,
        euriborRate: 3,
        euriborDate: ''
    };

    componentDidMount() {
        const { product } = this.props;

        if (Object.keys(product).length !== 0) {
            this.getLeasingInterestRate();
            return;
        }

        const interval = setInterval(
            () => {
                const { product } = this.props;
                if (Object.keys(product).length !== 0) {
                    clearInterval(interval);
                    this.getLeasingInterestRate();
                }
            },
            DEFAULT_INTERVAL
        );
    }

    getLeasingInterestRate() {
        this.fetchData(
            [LeasingQuery.getLeasingConfigQuery()],
            ({ storeConfig: { leasing_interest_rate, euribor_rate, euribor_date } = {} }) => {
                const parsedLeasingInterestRate = parseFloat(leasing_interest_rate);
                const parsedEuriborRate = parseFloat(euribor_rate);
                this.setState(
                    {
                        leasingInterestRate: parsedLeasingInterestRate,
                        euriborRate: parsedEuriborRate,
                        euriborDate: euribor_date
                    },
                    () => {
                        this.handleCalculate();
                    }
                );
            }
        );
    }

    containerProps() {
        const { product, storeData, code } = this.props;
        const {
            selectedPeriod, monthlyPayment, leasingInterestRate, euriborRate, euriborDate
        } = this.state;

        return {
            product,
            storeData,
            code,
            selectedPeriod,
            monthlyPayment,
            leasingInterestRate,
            euriborRate,
            euriborDate
        };
    }

    handlePeriodChange(value) {
        this.setState({ selectedPeriod: value }, () => {
            this.handleCalculate();
        });
    }

    calculatePrice() {
        const { product } = this.props;
        const {
            price_range,
            dynamic_price,
            type_id
        } = product;

        const { value } = getPrice(price_range, dynamic_price, {}, type_id).price.finalPrice;
        const { selectedPeriod, leasingInterestRate, euriborRate } = this.state;
        const downPaymentPercentage = 0.1;

        const interest = leasingInterestRate + euriborRate;

        // Check if the selected period is valid
        if (!LEASE_PERIODS.includes(selectedPeriod)) {
            return null;
        }

        // Calculate and return the monthly payment
        return this.calculateMonthlyPayment(value, downPaymentPercentage, interest, selectedPeriod);
    }

    handleCalculate() {
        const monthlyPayment = this.calculatePrice();

        if (monthlyPayment === null) {
            return;
        }

        this.setState({ monthlyPayment });
    }

    calculateMonthlyPayment(price, downPaymentPercentage, interestRate, period) {
        // Down payment calculation (10% of product price)
        const downPayment = price * downPaymentPercentage;

        // Loan amount calculation (product price minus down payment)
        const loanAmount = price - downPayment;

        // eslint-disable-next-line no-magic-numbers
        const interestRateDecimal = interestRate / DECIMAL_DIVISOR / MONTHS_IN_YEAR;

        // Lease period conversion (months)
        const leasePeriodMonths = parseInt(period, 10);

        // Monthly payment calculation using loan amount, interest rate, and lease period
        // eslint-disable-next-line max-len
        const monthlyPayment = (loanAmount * interestRateDecimal) / (1 - (1 + interestRateDecimal) ** -leasePeriodMonths);

        // Rounding the monthly payment to integer
        return Math.ceil(monthlyPayment);
    }

    render() {
        return (
            <ProductLease
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductLeaseContainer);

import MyAccountQuery from 'SourceQuery/MyAccount.query';
import {
    updateCustomerDetails
} from 'Store/MyAccount/MyAccount.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request';

export const CUSTOMER = 'customer';

export const ONE_MONTH_IN_SECONDS = 2628000;

export const requestCustomerData = (args, callback, instance) => {
    const query = MyAccountQuery.getCustomerQuery();

    const dispatch = args[0];

    return executePost(prepareQuery([query])).then(
        /** @namespace Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
        ({ customer }) => {
            dispatch(updateCustomerDetails(customer));
            BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
        },
        /** @namespace Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/dispatch/catch */
        (error) => {
            instance.logout(null, true, dispatch);
        }
    );
};

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            requestCustomerData
        }
    }
};

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import StickyFooter from './StickyFooter.component';

/** @namespace Scandipwa/Component/StickyFooter/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    code: state.ConfigReducer.code
});

/** @namespace Scandipwa/Component/StickyFooter/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/StickyFooter/Container */
export class StickyFooterContainer extends PureComponent {
    static propTypes = {
        code: PropTypes.string
    };

    static defaultProps = {
        code: 'lv'
    };

    containerProps() {
        const { code } = this.props;

        return { code };
    }

    render() {
        return (
            <StickyFooter
              { ...this.containerProps() }
            />
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StickyFooter);

/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */

import FlexibleFormsWidget from '../component/FlexibleFormsWidget';
import { FORM_WIDGET } from '../component/FlexibleFormsWidget/FlexibleFormsWidget.config';

const renderMap = (originalMember) => ({
    ...originalMember,
    [FORM_WIDGET]: {
        component: FlexibleFormsWidget
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        }
    }
};

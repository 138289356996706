/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import domToReact from 'html-react-parser/lib/dom-to-react';

import PrivacyPolicyButton from '../component/PrivacyPolicyButton';

/**
 * When the BE detects a link to the privacy policy, it sets the link location to this value
 * Same as in Controller/Consent/GetPrivacyInformation.php
 * @type {string}
 */
export const PRIVACY_POPUP_MAGIC_LINK = '#amasty-scandipwa-gdpr-popup-magic-link';

export const rules = (originalMember, instance) => [
    {
        query: { name: ['a'] },
        replace: replacePrivacyPolicyButton(originalMember.parserOptions, instance)
    },
    ...originalMember
];

const replacePrivacyPolicyButton = (parserOptions, instance) => ({ attribs, children }) => {
    const { href } = attribs;
    const { privacyPolicy } = instance.props;

    if (href === PRIVACY_POPUP_MAGIC_LINK) {
        return (
            <PrivacyPolicyButton privacyPolicy={ privacyPolicy }>
                { domToReact(children, parserOptions) }
            </PrivacyPolicyButton>
        );
    }

    return instance.replaceLinks({ attribs, children });
};

export default {
    'Component/Html/Component': {
        'member-property': {
            rules
        }
    }
};

/* eslint-disable max-len */
/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Aivars Arbidans <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Form from 'Component/Form';
import Popup from 'Component/Popup';

import { PRIVACY_SETTINGS_POPUP_ID } from './MyAccountPrivacySettingsPopup.config';

import './MyAccountPrivacySettingsPopup.style';

/** @namespace Gdpr/Component/MyAccountPrivacySettingsPopup/Component */
export class MyAccountPrivacySettingsPopupComponent extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            isDownload: PropTypes.bool,
            downloadData: PropTypes.func,
            isAnonymise: PropTypes.bool,
            anonymiseData: PropTypes.func,
            isDelete: PropTypes.bool,
            deleteData: PropTypes.func
        }).isRequired,
        onSuccess: PropTypes.func.isRequired
    };

    renderDescription() {
        const { payload } = this.props;

        if (payload) {
            const { isDownload, isDelete, isAnonymise } = payload;

            if (isDownload) {
                return __('Here you can download a copy of your personal data which we store for your account in CSV format.');
            }

            if (isDelete) {
                return __('Request to remove your account, together with all your personal data, will be processed by our staff. Deleting your account will remove all the purchase history, discounts, orders, invoices and all other information that might be related to your account or your purchases. All your orders and similar information will be lost. You will not be able to restore access to your account after we approve your removal request.');
            }

            if (isAnonymise) {
                return __('Anonymising your personal data means that it will be replaced with non-personal anonymous information and before that you will get your new login to your e-mail address (your password will stay the same). After this process, your e-mail address and all other personal data will be removed from the website.');
            }

            return null;
        }

        return null;
    }

    renderContent() {
        const { onSuccess } = this.props;

        return (
            <Form key="privacySettings" onSubmit={ onSuccess }>
                <button
                  block="Button"
                  mods={ {
                      Narrow: true,
                      Dark: true
                  } }
                  type="submit"
                >
                    { __('Proceed') }
                </button>
            </Form>
        );
    }

    render() {
        return (
            <Popup
              id={ PRIVACY_SETTINGS_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'MyAccountPrivacySettingsPopup' } }
            >
                { this.renderDescription() }
                { this.renderContent() }
            </Popup>
        );
    }
}

export default MyAccountPrivacySettingsPopupComponent;

/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Scandiweb/Gtm/Query/Purchase/Query */
export class PurchaseQuery {
    getPurchaseField(orderId, guestQuoteId) {
        const field = new Field('getPurchase')
            .setAlias('purchase')
            .addArgument('orderID', 'String!', orderId)
            .addFieldList(this.getPurchaseFields());

        if (!isSignedIn() && guestQuoteId) {
            field.addArgument('guestCartId', 'String', guestQuoteId);
        }

        return field;
    }

    getPurchaseFields() {
        return [
            'orderPaymentMethod',
            this.getCustomerField(),
            'orderShippingMethod',
            'revenue',
            'tax',
            'shipping',
            'coupon',
            'discount_amount',
            'additional_data',
            this.getProductField(),
            this.getShippingField()
        ];
    }

    getCustomerField() {
        return new Field('customerData')
            .setAlias('customer')
            .addFieldList(this.getCustomerFields());
    }

    getCustomerFields() {
        return [
            'firstname',
            'lastname',
            'phone'
        ];
    }

    getProductField() {
        return new Field('purchaseProducts')
            .setAlias('products')
            .addFieldList(this.getProductFields());
    }

    getProductFields() {
        return [
            'name',
            'id',
            'price',
            'quantity',
            'full_price',
            'make',
            'model',
            'first_registered',
            'mileage',
            'body_style',
            'displacement',
            'fuel',
            'power',
            'gearbox',
            'gearbox_search',
            'drivetrain_suspension',
            'drivetrain_search',
            'car_color',
            'technical_inspection',
            'equipment_search',
            'dealer',
            'category',
            'dimensions'
        ];
    }

    getShippingField() {
        return new Field('shippingAddress')
            .addFieldList(this.getShippingFields());
    }

    getShippingFields() {
        return [
            'street',
            'city',
            'region_id',
            'region',
            'postcode',
            'email'
        ];
    }
}
export default new PurchaseQuery();

/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { roundPrice } from 'Util/Price';

import { ATTRIBUTE_CODES } from './common';

/** @namespace Scandiweb/Gtm/Data/Compare/getCompareProductPriceData */
export const getCompareProductPriceData = async (result) => {
    const {
        price_range: {
            minimum_price: {
                final_price: {
                    value: full_price = null
                } = {},
                reservation_price: {
                    value: price = null
                } = {}
            }
        } = {}
    } = result.items.slice(-1)[0].product;

    return {
        price: +roundPrice(price || 0),
        full_price: +roundPrice(full_price || 0)
    };
};

/** @namespace Scandiweb/Gtm/Data/Compare/getCompareProductAttributesData */
export const getCompareProductAttributesData = async (result) => {
    const filteredAttributes = result.items.slice(-1)[0].attributes.reduce(
        (acc, { code, value }) => {
            if (ATTRIBUTE_CODES.includes(code)) {
                return { ...acc, [code]: value !== '-' ? value : '' };
            }

            return acc;
        }, {}
    );

    return {
        brand: filteredAttributes.make,
        variant: filteredAttributes.model,
        make: filteredAttributes.make,
        model: filteredAttributes.model,
        first_registered: filteredAttributes.first_registered,
        mileage: filteredAttributes.mileage,
        body_style: filteredAttributes.body_style,
        displacement: filteredAttributes.displacement,
        fuel: filteredAttributes.fuel,
        power: filteredAttributes.power,
        gearbox: filteredAttributes.gearbox,
        gearbox_search: filteredAttributes.gearbox.gearbox_search,
        drivetrain_suspension: filteredAttributes.drivetrain_suspension,
        drivetrain_search: filteredAttributes.drivetrain_search,
        car_color: filteredAttributes.car_color,
        technical_inspection: filteredAttributes.technical_inspection,
        equipment_search: filteredAttributes.equipment_search,
        dealer: filteredAttributes.dealer
    };
};

/** @namespace Scandiweb/Gtm/Data/Compare/getCompareProductData */
export const getCompareProductData = async (result) => {
    const {
        sku,
        name
    } = result.items.slice(-1)[0].product;

    const {
        price,
        full_price
    } = await getCompareProductPriceData(result);

    const {
        brand,
        variant,
        make,
        model,
        first_registered,
        mileage,
        body_style,
        displacement,
        fuel,
        power,
        gearbox,
        gearbox_search,
        drivetrain_suspension,
        drivetrain_search,
        car_color,
        technical_inspection,
        equipment,
        dealer
    } = await getCompareProductAttributesData(result);

    return {
        id: sku || '',
        name: name || '',
        price,
        brand,
        variant,
        dimension1: full_price,
        dimension2: make || '',
        dimension3: model || '',
        dimension4: first_registered || '',
        dimension5: mileage || '',
        dimension6: body_style || '',
        dimension7: displacement || '',
        dimension8: fuel || '',
        dimension9: power || '',
        dimension10: (gearbox !== '' && gearbox !== undefined) ? gearbox : gearbox_search || '',
        dimension11: (drivetrain_suspension !== '' && drivetrain_suspension !== undefined)
            ? drivetrain_suspension : drivetrain_search || '',
        dimension12: car_color || '',
        dimension13: technical_inspection || '',
        dimension14: equipment || '',
        dimension15: dealer || ''
    };
};

/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { roundPrice } from 'SourceUtil/Price';

import { getPageData } from './page';

export const DL_VAL_CATEGORY_LIMIT = 5;

/** @namespace Scandiweb/Gtm/Data/Product/getProductVariantData */
export const getProductVariantData = async (product) => {
    const { variants = [] } = product;
    const { sku } = variants[0] || {};
    return sku || '';
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductDimensionsData */
export const getProductDimensionsData = async (product) => {
    const { variants = [], dimensions } = product;
    const { dimensions: childDimensions } = variants[0] || {};
    const rawDimensions = childDimensions || dimensions;
    return rawDimensions ? JSON.parse(rawDimensions) : {};
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductCategoriesData */
export const getProductCategoriesData = async ({ categories = [] }) => (
    categories.slice(0, DL_VAL_CATEGORY_LIMIT).map(({ name }) => name).join('/')
);

/** @namespace Scandiweb/Gtm/Data/Product/getProductAttributesData */
export const getProductAttributesData = async (product) => {
    const { attributes } = product;

    return {
        brand: Object.values(attributes?.make?.attribute_options ?? {})?.[0]?.label,
        variant: Object.values(attributes?.model?.attribute_options ?? {})?.[0]?.label,
        make: Object.values(attributes?.make?.attribute_options ?? {})?.[0]?.label,
        model: Object.values(attributes?.model?.attribute_options ?? {})?.[0]?.label,
        first_registered: attributes?.first_registered?.attribute_value,
        mileage: attributes?.mileage?.attribute_value,
        body_style: Object.values(attributes?.body_style?.attribute_options ?? {})?.[0]?.label,
        displacement: attributes?.displacement?.attribute_value,
        fuel: Object.values(attributes?.fuel?.attribute_options ?? {})?.[0]?.label,
        power: attributes?.power?.attribute_value,
        gearbox: Object.values(attributes?.gearbox?.attribute_options ?? {})?.[0]?.label,
        gearbox_search: Object.values(attributes?.gearbox_search?.attribute_options ?? {})?.[0]?.label,
        drivetrain_suspension: Object.values(attributes?.drivetrain_suspension?.attribute_options ?? {})?.[0]?.label,
        drivetrain_search: Object.values(attributes?.drivetrain_search?.attribute_options ?? {})?.[0]?.label,
        car_color: Object.values(attributes?.car_color?.attribute_options ?? {})?.[0]?.label,
        technical_inspection: attributes?.technical_inspection?.attribute_value,
        equipment: (attributes?.equipment_search?.attribute_options !== undefined)
            ? Object.keys(attributes.equipment_search.attribute_options).map(
                (value) => attributes.equipment_search.attribute_options[value].label
            ).join(', ')
            : attributes?.equipment_search?.attribute_options,
        dealer: Object.values(attributes?.dealer?.attribute_options ?? {})?.[0]?.label
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductPriceData */
export const getProductPriceData = async (product) => {
    const { variants = [], type_id, price_range } = product;

    if (!price_range) {
        return -1;
        // ^^^ must be replaced, if price range is not present
    }

    if (type_id === 'grouped') {
        return 0;
    }

    const {
        price_range: {
            minimum_price: {
                final_price: {
                    value: full_price = null
                } = {},
                reservation_price: {
                    value: price = null
                } = {}
            }
        } = {}
    } = variants[0] || product;

    return {
        price: +roundPrice(price || 0),
        full_price: +roundPrice(full_price || 0)
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductData */
export const getProductData = async (product) => {
    const {
        sku,
        name
    } = product;

    const {
        price,
        full_price
    } = await getProductPriceData(product);

    const {
        brand,
        variant,
        make,
        model,
        first_registered,
        mileage,
        body_style,
        displacement,
        fuel,
        power,
        gearbox,
        gearbox_search,
        drivetrain_suspension,
        drivetrain_search,
        car_color,
        technical_inspection,
        equipment,
        dealer
    } = await getProductAttributesData(product);

    return {
        id: sku,
        name,
        price,
        brand,
        variant,
        category: await getProductCategoriesData(product),
        dimension1: full_price,
        dimension2: make || '',
        dimension3: model || '',
        dimension4: first_registered || '',
        dimension5: mileage || '',
        dimension6: body_style || '',
        dimension7: displacement || '',
        dimension8: fuel || '',
        dimension9: power || '',
        dimension10: gearbox || gearbox_search || '',
        dimension11: drivetrain_suspension || drivetrain_search || '',
        dimension12: car_color || '',
        dimension13: technical_inspection || '',
        dimension14: equipment || '',
        dimension15: dealer || ''
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductListEntryData */
export const getProductListEntryData = async (
    product,
    position,
    forcedList
) => {
    const { pageType: list } = await getPageData();
    // ^^^ Reuse page data as list information

    return {
        ...await getProductData(product),
        list: forcedList || list,
        position
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getProductDetailsData */
export const getProductDetailsData = async (product) => ({
    ecommerce: {
        detail: {
            products: [await getProductData(product)]
        }
    }
});

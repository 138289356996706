import { FieldSelectContainer as SourceContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';
/** @namespace Scandipwa/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceContainer {
    state = {
        ...this.state,
        fieldValue: ''
    };

    componentDidMount() {
        const { attr } = this.props;
        const { defaultValue } = attr;

        if ((defaultValue !== '') && (defaultValue !== undefined)) {
            this.setState({ fieldValue: defaultValue });
        }
    }

    /**
     * Overridden to set fieldValue state value
     */
    handleSelectListOptionClick(option) {
        const { changeValueOnDoubleClick, events: { onChange } = {} } = this.props;
        const { value, target: { value: targetValue } = {} } = option;

        const fieldValue = value || targetValue || '';

        this.setState({ fieldValue });

        if (changeValueOnDoubleClick) {
            this.fieldRef.value = this.fieldRef.value === value ? '' : fieldValue;
        } else {
            this.fieldRef.value = fieldValue;
        }

        if (onChange) {
            onChange(fieldValue);
        }
    }

    /**
     * Overridden to pass fieldValue prop
     */
    containerProps() {
        const { fieldValue } = this.state;

        return {
            ...super.containerProps(),
            fieldValue
        };
    }
}

export default FieldSelectContainer;

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/multiple-wishlists
 */

import { UPDATE_PRODUCT_LIST_INFO } from 'Store/ProductListInfo/ProductListInfo.action';

const _aroundGetInitialState = (args, callback) => ({
    ...callback(...args),
    currencyRate: 1
});

export const reduceFilters = (filters) => filters.reduce((co, item) => {
    const {
        request_var: attribute_code,
        name: attribute_label,
        position: attribute_position,
        filter_items,
        is_boolean,
        has_swatch
    } = item;

    const { attribute_values, attribute_options } = filter_items.reduce((attribute, option) => {
        const { value_string } = option;
        const { attribute_values, attribute_options } = attribute;

        attribute_values.push(value_string);

        return {
            ...attribute,
            attribute_options: {
                ...attribute_options,
                [value_string]: option
            }
        };
    }, { attribute_values: [], attribute_options: {} });

    return {
        ...co,
        [attribute_code]: {
            attribute_code,
            attribute_label,
            attribute_position,
            attribute_values,
            attribute_type: 'select',
            attribute_options,
            is_boolean,
            has_swatch
        }
    };
}, {});

const ProductListReducer = (args, callback, instance) => {
    const [state, action] = args;
    const { type } = action;

    if (type !== UPDATE_PRODUCT_LIST_INFO) {
        return callback.apply(instance, args);
    }

    const {
        selectedFilter,
        products: {
            filters: availableFilters = [],
            min_price,
            max_price,
            min_year,
            max_year,
            min_power,
            max_power,
            min_mileage,
            max_mileage,
            sort_fields: sortFields,
            currency_rate: currencyRate
        } = {}
    } = action;

    return {
        ...state,
        filters: reduceFilters(availableFilters),
        sortFields,
        minPrice: Math.floor(min_price),
        maxPrice: Math.ceil(max_price),
        min_year,
        max_year,
        min_power,
        max_power,
        min_mileage,
        max_mileage,
        isLoading: false,
        selectedFilter,
        currencyRate
    };
};

export default {
    'Store/ProductListInfo/Reducer/getInitialState': {
        function: _aroundGetInitialState
    },
    'Store/ProductListInfo/Reducer/ProductListReducer': {
        function: ProductListReducer
    }
};

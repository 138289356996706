import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Leasing/Query */
export class LeasingQuery {
    _getLeasingFields() {
        return [
            'leasing_interest_rate',
            'euribor_rate',
            'euribor_date'
        ];
    }

    getLeasingConfigQuery() {
        return new Field('storeConfig')
            .addFieldList(this._getLeasingFields());
    }
}

export default new LeasingQuery();

/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

// import { getCompareProductData } from '../data/compare';
import { getCompareProductData } from '../data/compare';
import { GTM_EVENT_KEY_ADD_TO_COMPARE_LIST } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/GoogleTagManager/Event/Compare/addToCompareList */
export const fireAddToCompareListEvent = debounceCallback(async (result) => {
    pushToDataLayer({
        event: GTM_EVENT_KEY_ADD_TO_COMPARE_LIST,
        product: await getCompareProductData(result)
    });
});

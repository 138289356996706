/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-lines */
import domToReact from 'html-react-parser/lib/dom-to-react';

import { decodeUrl, makeId } from '../../../scandiweb-adobe-page-builder/src/util/functions';

export const isNotEmptyArr = (arr) => Array.isArray(arr) && arr.length;

export class HtmlComponent {
  originalMember;

  baseInstance;

  parserOptions;

  // eslint-disable-next-line arrow-body-style
  overrideRules = (originalMember, instance) => {
      const { type } = instance.props;
      if (type === 'Blog') {
          return originalMember.filter((i) => !(i.query && i.query.name && i.query.name[0] === 'img'));
      }

      return originalMember;
      // We filter out img tag and implement it separately
      // because its behaviour is different from usual in page-builder
  };

  // Override parserOptions:
  // - Add our rules which is based on data-content-type attr
  // - Process special attr such as: data-background-images
  getParserOptions = (originalMember, instance) => {
      const { type } = instance.props;
      if (type === 'Blog') {
          this.originalMember = originalMember;
          this.baseInstance = instance;
          const replace = (domNode) => {
              const { name } = domNode;
              if (name !== 'style') {
                  if (domNode.data && !domNode.data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                      return <></>;
                  }

                  if (name === 'img') {
                      const { attribs: { class: img_class } } = domNode;
                      if (img_class === 'pagebuilder-mobile-hidden' || img_class === 'pagebuilder-mobile-only') {
                          const { attribs: { src } } = domNode;
                          const { base_url } = instance.props;
                          domNode.attribs.src = `${base_url}${decodeUrl(src)}`;
                      } else {
                          return instance.replaceImages(domNode);
                      }
                  }
                  this.replaceSpecialDomAttrs(domNode);
              }
          };

          this.parserOptions = {
              ...this.originalMember,
              replace,
              trim: true
          };

          return this.parserOptions;
      }

      return originalMember;
  };

  replaceSpecialDomAttrs(domNode) {
      const { attribs: domAttrs } = domNode;
      if (!domAttrs || Object.keys(domAttrs).length === 0) {
          return;
      }
      if (domAttrs['data-background-images']) {
          this.handleDynamicBackgroundImages(domAttrs, domNode);
      }
  }

  // Magento page-builder is using the below objects:
  // {"desktop_image": "http://host/media/wysiwyg/background.jpg", "mobile_image": "http://host/media/wysiwyg/banner-1.jpg"}
  // OR {"desktop_image": "{{media url=wysiwyg/wide-banner-background.jpg}}"}
  // to generate 2 unique classnames for desktop & mobile.
  // Let just generate 1 unique classname and use media-query for mobile
  handleDynamicBackgroundImages(domAttrs, domNode) {
      try {
          const images = JSON.parse(domAttrs['data-background-images'].replace(/\\(.)/mg, '$1')) || {};

          // eslint-disable-next-line no-magic-numbers
          const uniqClassName = `bg-image-${ makeId(5)}`;
          // eslint-disable-next-line fp/no-let
          let css = '';
          if (images.desktop_image) {
              // Sometimes magento returns an stringify object instead of a string. We need to decode to a URL string
              const imageUrl = decodeUrl(images.desktop_image) || images.desktop_image;
              css += `
        .${uniqClassName} {
          background-image: url(/${imageUrl});
        }`;
          }
          if (images.mobile_image) {
              const imageUrl = decodeUrl(images.mobile_image) || images.mobile_image;
              css += `
          @media only screen and (max-width: 810px) {
            .${uniqClassName} {
              background-image: url(/${imageUrl});
            }
          }
        `;
          }
          // Let's add it to our <head /> tag
          if (css) {
              const { head } = document;
              const style = document.createElement('style');
              head.appendChild(style);
              style.type = 'text/css';
              if (style.styleSheet) {
                  // This is required for IE8 and below.
                  style.styleSheet.cssText = css;
              } else {
                  style.appendChild(document.createTextNode(css));
              }
              domNode.attribs.class = `${domNode.attribs.class || ''} ${uniqClassName}`;
          }
      } catch (e) {
          // Just forget it
          console.log(e);
      }
  }
}

const { getParserOptions } = new HtmlComponent();
export default {
    'Component/Html/Component': {
        'member-property': {
            parserOptions: getParserOptions
        }
    }
};

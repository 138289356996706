/* eslint-disable */
/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Html from 'Component/Html';
import Popup from 'Component/Popup';
import { executeGet } from 'Util/Request';
import { prepareQuery } from 'Util/Query';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import PrivacyInformationQuery from '../../query/PrivacyInformation.query';
import { PRIVACY_POLICY_POPUP_ID, PRIVACY_POLICY_POPUP_FIRST_VISIT } from './PrivacyPolicyPopup.config';

/** @namespace Gdpr/Component/PrivacyPolicyPopup/Component */
export class PrivacyPolicyPopupComponent extends PureComponent {
    static propTypes = {
        payload: PropTypes.shape({
            PrivacyPolicyPopupComponent: PropTypes.shape({
                privacyPolicy: PropTypes.string
            })
        })
    };

    state = {
        isLoading: false,
        isDisabled: false,
        privacyPolicy: ''
    };

    static defaultProps = {
        payload: {}
    };

    componentDidMount() {
        this.requestPrivacyInformation();
    }

    requestPrivacyInformation() {
        this.setState(
            { isLoading: true },
            () => {
                executeGet(prepareQuery(PrivacyInformationQuery.getQuery()))
                    .then(
                        /** @namespace Gdpr/Component/PrivacyPolicyPopup/Component/PrivacyPolicyPopupComponent/requestPrivacyInformation/setState/catch/fetchQuery/then */
                        ({ getPrivacyInformation: { consents, privacyPolicy } }) => {
                            if (consents === null) {
                                this.setState({
                                    isLoading: false,
                                    isDisabled: true
                                });
                            } else {
                                this.setState({
                                    isLoading: false,
                                    consents,
                                    privacyPolicy
                                });
                            }
                        },
                        /** @namespace Gdpr/Component/PrivacyPolicyPopup/Component/PrivacyPolicyPopupComponent/requestPrivacyInformation/setState/catch/fetchQuery/then/catch */
                        (e) => console.error(e)
                    );
            }
        );
    }

    // componentDidUpdate(prevProps, prevState) {
    //     const { showOnFirstVisit, showPopup } = this.props;
    //     const { showOnFirstVisit: prevShowOnFirstVisit } = prevProps;
    //     const { privacyPolicy } = this.state;
    //     const { privacyPolicy: prevPrivacyPolicy } = prevState;
    //
    //     if (privacyPolicy !== prevPrivacyPolicy || showOnFirstVisit !== prevShowOnFirstVisit) {
    //         if (BrowserDatabase.getItem(PRIVACY_POLICY_POPUP_FIRST_VISIT)) {
    //             showPopup({});
    //         }
    //     }
    // }

    renderContent() {
        const { payload: { PrivacyPolicyPopupComponent: { privacyPolicy = false } = {} } = {} } = this.props;
        const { showOnFirstVisit } = false;
        const { privacyPolicy: statePrivacyPolicy } = this.state;

        if (statePrivacyPolicy && !BrowserDatabase.getItem(PRIVACY_POLICY_POPUP_FIRST_VISIT) && showOnFirstVisit) {
            return (
                <article>
                    <Html content={ statePrivacyPolicy } />
                </article>
            );
        }

        if (!privacyPolicy) {
            return null;
        }

        return (
            <article>
                <Html content={ privacyPolicy || statePrivacyPolicy } />
            </article>
        );
    }

    onClose = () => {
        const { showOnFirstVisit } = false;

        if (showOnFirstVisit) {
            BrowserDatabase.setItem(true, PRIVACY_POLICY_POPUP_FIRST_VISIT, ONE_MONTH_IN_SECONDS);
        }
    }

    render() {
        const { showOnFirstVisit } = false;

        return (
            <Popup
              id={ PRIVACY_POLICY_POPUP_ID }
              clickOutside={ false }
              mix={ { block: 'MyAccountPrivacySettingsPopup' } }
              isStatic={ showOnFirstVisit && !BrowserDatabase.getItem(PRIVACY_POLICY_POPUP_FIRST_VISIT) }
              onClose={ this.onClose }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default PrivacyPolicyPopupComponent;

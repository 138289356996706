/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { KEY_PRICE } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';

export const KEY_YEAR = 'year';
export const KEY_POWER_SEARCH = 'power_search';
export const KEY_MILEAGE_SEARCH = 'mileage_search';

export const _getNewFilterArray = (args, callback, instance) => {
    const { customFiltersValues: { price, year, power_search, mileage_search }, customFiltersValues } = instance.props;
    const [filterKey, value] = args;

    if (filterKey === KEY_PRICE) {
        return price && value === undefined
            ? []
            : [value];
    }

    if (filterKey === KEY_YEAR) {
        return year && value === undefined
            ? []
            : [value];
    }

    if (filterKey === KEY_POWER_SEARCH) {
        return power_search && value === undefined
            ? []
            : [value];
    }

    if (filterKey === KEY_MILEAGE_SEARCH) {
        return mileage_search && value === undefined
            ? []
            : [value];
    }

    return callback.apply(instance, args);
};

export default {
    'Component/CategoryFilterOverlay/Container': {
        'member-function': {
            _getNewFilterArray
        }
    }
};

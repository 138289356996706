/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 */

const config = () => ({
    sortKey: 'random',
    sortDirection: 'ASC'
});

export default {
    'Route/CategoryPage/Container': {
        'member-property': {
            config
        }
    }
};

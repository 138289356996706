import { PostsDetails, PostsListing } from './Router.plugin';

export const MF_BLOG_POST = 'MF_BLOG_POST';
export const MF_BLOG_CATEGORY = 'MF_BLOG_CATEGORY';
export const MF_BLOG_TAG = 'MF_BLOG_TAG';

export const getTypeSpecificProps = (args, callback, instance) => {
    const isLoading = instance.getIsLoading();

    const {
        urlRewrite: {
            id,
            identifier = ''
        }
    } = instance.props;

    if (instance.getType() === MF_BLOG_POST) {
        if (isLoading) {
            return {};
        }

        return { blog_post_id: id, identifier };
    }

    if (instance.getType() === MF_BLOG_CATEGORY) {
        if (isLoading) {
            return {};
        }

        return { blog_category_id: id, identifier };
    }

    if (instance.getType() === MF_BLOG_TAG) {
        if (isLoading) {
            return {};
        }

        return { blog_tag_id: id, identifier };
    }

    return callback(...args);
};

export const stateMapping = (originalMember) => ({
    ...originalMember,
    blog_post: MF_BLOG_POST,
    blog_category: MF_BLOG_CATEGORY,
    blog_tag: MF_BLOG_TAG
});

export const renderContent = (args, callback, instance) => {
    const { props, type } = instance.props;

    switch (type) {
    case MF_BLOG_POST:
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        return <PostsDetails { ...props } />;
    case MF_BLOG_CATEGORY:
        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
        return <PostsListing { ...props } />;
    case MF_BLOG_TAG:
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            return <PostsListing { ...props } />;
    default:
        return callback(...args);
    }
};

export const getIsLoading = (args, callback, instance) => {
    const {
        match: {
            params: {
                handle
            } = {}
        } = {},
        requestedUrl
    } = instance.props;

    if (handle) {
        return handle.startsWith('/') ? handle : `/${handle}` !== requestedUrl;
    }

    return callback(...args);
};

export const requestUrlRewrite = (args, callback, instance) => {
    const {
        match: {
            params: {
                handle
            } = {}
        } = {},
        requestUrlRewrite
    } = instance.props;

    if (handle) {
        const pathname = handle.startsWith('/') ? handle : `/${handle}`;
        return requestUrlRewrite(pathname);
    }

    return callback(...args);
};

export default {
    'Route/UrlRewrites/Container': {
        'static-member': {
            stateMapping
        },
        'member-function': {
            getTypeSpecificProps,
            requestUrlRewrite,
            getIsLoading
        }
    },
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent
        }
    }
};

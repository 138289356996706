/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

export const UrlRewrites = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "posts-listing" */ 'Route/UrlRewrites')
);

export const PostsDetails = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "posts-details" */ '../route/PostsDetails')
);

export const PostsListing = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "posts-listing" */ '../route/PostsListing')
);

export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const switchItems = (originalMember) => [
    ...originalMember,
    {
        component: (
                <Route
                  path={ withStoreRegex('/blog/:handle') }
                  render={ (props) => <UrlRewrites { ...props } /> }
                />
        ),
        position: 100
    },
    {
        component: (
                <Route
                  path={ withStoreRegex('/blog') }
                  render={ (props) => <PostsListing { ...props } /> }
                />
        ),
        position: 110
    }
];

export default {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItems
        }
    }
};
